import React, { useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal';

const OpenModal = (props) => {
    return (
        <>
            <Modal
                open={props.open}
                disablePortal
                disableEnforceFocus
                disableAutoFocus
            >
                <div className="modal-dialog modal-dialog-centered w-75 m-auto">
                    <div className="modal-content" style={{ borderRadius: '12px' }}>
                        <div className="mt-3" style={{
                            textAlign: "center"
                        }}>
                            <div className="container">

                                <span style={{
                                    fontFamily: "DB Heavent",
                                    fontStyle: "normal",
                                    fontWeight: "bold",
                                    fontSize: "25px",
                                    lineHeight: "28px",
                                    color: "#000000",
                                }}>{props.title}</span>
                                <br />
                                <span style={{
                                    fontFamily: "DB Heavent",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: "22px",
                                    color: "#949494"
                                }}>{props.content}</span>
                            </div>
                        </div>
                        <svg height="12px" width="100%"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1">
                            <line className="dashed" x1="0"
                                x2="100%" y1="20" y2="20" />
                        </svg>
                        <div className="btn-group">
                            <button
                                type="button" className="btn"
                                style={{
                                    fontFamily: "DB Heavent",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: "22px",
                                    color: "#949494"
                                }}
                                onClick={() => props.handleClose(false)}
                            >ยกเลิก</button>
                            <button
                                disabled={props.disabled}
                                type="button" className="btn"
                                style={{
                                    fontFamily: "DB Heavent",
                                    fontStyle: "normal",
                                    fontWeight: "bold",
                                    fontSize: "22px",
                                    lineHeight: "28px",
                                    color: "#000000",
                                }}
                                onClick={() => props.handleConfirm()}
                            >ยืนยัน</button>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    )
}
export default OpenModal
