import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import "./Upload.scss";
import HeaderBackPoint from "../../components/HeaderBackPoint"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Modal from '@material-ui/core/Modal';
import { apiUrl } from '../../constants';
import axios from "axios";
import Swal from "sweetalert2";
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import OpenModalConsent from "../../components/OpenModalConsent"

const GreenCheckbox = withStyles({
  root: {
    color: '#009343',
    '&$checked': {
      color: '#009343',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Address = (props) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [coverToken, setCoverToken] = useState();
  const [coverId, setCoverId] = useState();
  const [value, setValue] = useState(-1);
  const [loadButton, setLoadButton] = useState(false);
  const [image, setImage] = useState(null)
  const [fileName, setFileName] = useState("No selected file")

  const [checked, setChecked] = React.useState(false);
  const [checkedConsent, setCheckedConsent] = React.useState(false);
  useEffect(() => {
    const { addressSelect } = props.location.state
    var token = localStorage.getItem("token")
    var cover_token = token ? JSON.parse(token) : ""
    var id = localStorage.getItem("id")
    var cover_id = id ? JSON.parse(id) : ""

    setCoverToken(cover_token)
    setCoverId(cover_id)
    setValue(addressSelect.id || null)
    setLoadButton(true)
  }, [])

  const gotohome = () => {
    setOpen(false);
    history.push(`/privilege?token=${coverToken}`)
  }

  const handleConfirm = (cover_privilege_redeem_id) => {
    const apiUrls = `${apiUrl}/privilege/redeem/setAddress`;
    axios({
      method: "post",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${coverToken}`,
      },
      data: {
        privilegeRedeemIds: cover_privilege_redeem_id,
        clientAddressId: value,
        cardIdImage: image
      }
    }).then((res) => {
      setOpen(true);
    }).catch(function (error) {
      if (error.response) {
        Swal.fire({
          title: "ผิดพลาด",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "ตกลง",
          confirmButtonColor: '#00833b',
        });
      }
    });
  }

  const redeem = async () => {
    setLoadButton(true)
    const { addressSelect, cover_data_redeem } = props.location.state
    delete cover_data_redeem.point
    if (addressSelect.is_address_complete) {
      const apiUrls = `${apiUrl}/privilege/redeem`;
      axios({
        method: "post",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${coverToken}`,
        },
        data: cover_data_redeem,
      }).then((res) => {
        setLoadButton(false)

        let privilege_redeem_id_data = []
        res.data.map((data, key) => {
          privilege_redeem_id_data.push(data.privilege_redeem_id)
        })

        handleConfirm(privilege_redeem_id_data)
      }).catch(function (error) {
        setLoadButton(false)
        if (error.response) {
          setChecked(false)
          Swal.fire({
            title: "แลกรับสิทธิ์ผิดพลาด !",
            text: error.response.data.message,
            icon: "error",
            confirmButtonText: "ตกลง",
            confirmButtonColor: '#00833b',
          });
        }
      });
    } else {
      setChecked(false)
      Swal.fire({
        title: "แลกรับสิทธิ์ผิดพลาด !",
        text: "กรุณาเลือที่อยู่ให้ถูกต้อง",
        icon: "error",
        confirmButtonText: "ตกลง",
        confirmButtonColor: '#00833b',
      });
    }
  }

  const handleCheckbox = (checked) => {
    setCheckedConsent(true)
    // setChecked(checked)
    setLoadButton(!checked)
  }

  const handleCheckboxModal = (checked) => {
    setCheckedConsent(checked)
    setChecked(checked === false ? false : true)
    setLoadButton(checked === false ? true : false)
  }



  const handleUpload = async e => {
    if (e.target.files.length) {
      e.preventDefault()
      var _URL = window.URL || window.webkitURL;
      var file = e.target.files[0]
      var img = new Image();
      img.src = _URL.createObjectURL(file);
      img.onload = function () {
        handlepicUploadItemPic(file)
      };
    }
  }
  const handlepicUploadItemPic = async file => {
    var formdata = new FormData()
    formdata.append('image', file, file.name)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }
    fetch(`${apiUrl}/upload`, requestOptions)
      .then(response => response.text())
      .then(result => {
        let res = JSON.parse(result)
        setFileName(`${res.filename}`)
        setImage(`${res.url}`)
      })
      .catch(error => console.log('error', error))
  }

  return (
    <>
      <div className="address-page">
        <HeaderBackPoint
          topic={"แนบสำเนาบัตรประชาชน"}
          // add={"+ เพิ่มที่อยู่"}
          path={`/Detail?token=${coverToken}&id=${coverId}`}
        />
      </div>
      <svg height="11px" width="100%"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1">

        <line className="dashed" x1="0"
          x2="100%" y1="20" y2="20" />
      </svg>

      <div className="address-page-body">
        <div>
          <div className="container">
            <h4 className='text-title font-weight-bold'>อัปโหลด</h4>
            <form
              onClick={() => document.querySelector(".input-field").click()}
            >
              <input type="file" accept='image/*' className='input-field' hidden
                // onChange={({ target: { files } }) => {
                //   if (files.length > 0) {
                //     files[0] && setFileName(files[0].name)
                //     setImage(URL.createObjectURL(files[0]))
                //   }
                // }}
                onChange={handleUpload}
              />

              {image ?
                <>
                  <img src={image} width={150} alt={image} />

                  {/* <svg className="ml-2" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      setFileName("No selected File")
                      setImage(null)
                    }}
                  >
                    <path d="M2.75 5.5H4.58333H19.25" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.33337 5.49998V3.66665C7.33337 3.18042 7.52653 2.7141 7.87035 2.37028C8.21416 2.02647 8.68048 1.83331 9.16671 1.83331H12.8334C13.3196 1.83331 13.7859 2.02647 14.1297 2.37028C14.4736 2.7141 14.6667 3.18042 14.6667 3.66665V5.49998M17.4167 5.49998V18.3333C17.4167 18.8195 17.2236 19.2859 16.8797 19.6297C16.5359 19.9735 16.0696 20.1666 15.5834 20.1666H6.41671C5.93048 20.1666 5.46416 19.9735 5.12034 19.6297C4.77653 19.2859 4.58337 18.8195 4.58337 18.3333V5.49998H17.4167Z" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.8334 10.0833V15.5833" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.16663 10.0833V15.5833" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg> */}

                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none"
                    onClick={() => {
                      setFileName("No selected File")
                      setImage(null)
                    }}>
                    <g filter="url(#filter0_d_1_6988)">
                      <circle cx="16.5" cy="12.5" r="12.5" fill="white" />
                    </g>
                    <defs>
                      <filter id="filter0_d_1_6988" x="0" y="0" width="33" height="33" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_6988" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_6988" result="shape" />
                      </filter>
                    </defs>
                  </svg> */}
                </>
                :
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path d="M17.4167 10.0833H4.58341C4.07715 10.0833 3.66675 10.4937 3.66675 10.9999C3.66675 11.5062 4.07715 11.9166 4.58341 11.9166H17.4167C17.923 11.9166 18.3334 11.5062 18.3334 10.9999C18.3334 10.4937 17.923 10.0833 17.4167 10.0833Z" fill="#3F4253" />
                    <path d="M11.9167 17.4166L11.9167 4.58329C11.9167 4.07703 11.5063 3.66663 11.0001 3.66663C10.4938 3.66663 10.0834 4.07703 10.0834 4.58329L10.0834 17.4166C10.0834 17.9229 10.4938 18.3333 11.0001 18.3333C11.5063 18.3333 11.9167 17.9229 11.9167 17.4166Z" fill="#3F4253" />
                  </svg>
                  <p style={{fontSize:"16pt",textAlign:"center"}}>อัปโหลดรูปสำเนาบัตรประชาชนพร้อมลายเซ็นต์ หรือ ภพ20 (รูปแบบบริษัท)</p>
                </>
              }

            </form>


            <h4 className='text-title font-weight-bold mt-3'>ที่อยู่ในการจัดส่ง</h4>
            <div className="row mt-3 card-address">
              <div className="col-8">
                <FormControlLabel style={{ display: 'inline-flex', alignItems: 'normal' }} control={
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <circle cx="13" cy="13" r="13" fill="#009343" />
                    <path d="M11.3964 16.3107L8.14644 13.0607C7.95119 12.8654 7.95119 12.5488 8.14644 12.3535L8.85353 11.6464C9.04878 11.4512 9.36539 11.4512 9.56064 11.6464L11.75 13.8358L16.4394 9.14644C16.6346 8.95119 16.9512 8.95119 17.1465 9.14644L17.8535 9.85355C18.0488 10.0488 18.0488 10.3654 17.8535 10.5607L12.1035 16.3107C11.9083 16.5059 11.5917 16.5059 11.3964 16.3107Z" fill="white" />
                  </svg>}
                  checked={true}
                  label={
                    <div className="ml-2">
                      <div className="text-title font-weight-bold">
                        {props.location.state.addressSelect.name}
                      </div>
                      <div className="text-address">
                        {props.location.state.addressSelect.detail} {props.location.state.addressSelect.subDistrict?.name_th} {props.location.state.addressSelect.district.name_th} {props.location.state.addressSelect.province.name_th} {props.location.state.addressSelect.post_code}
                      </div>
                      <div className="text-tal">
                        {props.location.state.addressSelect.phone}
                      </div>


                    </div>
                  } />


              </div>

              <div className="col-4 pt-1 d-flex justify-content-end">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    history.push(`/CreateAddress?token=${coverToken}&id=${coverId}`, { mode: 1, data: props.location.state.addressSelect })
                  }}
                >
                  <path d="M8.60386 2.7923H2.68975C2.2416 2.7923 1.8118 2.97032 1.49492 3.28721C1.17803 3.6041 1 4.0339 1 4.48204V16.3103C1 16.7584 1.17803 17.1882 1.49492 17.5051C1.8118 17.822 2.2416 18 2.68975 18H14.518C14.9661 18 15.3959 17.822 15.7128 17.5051C16.0297 17.1882 16.2077 16.7584 16.2077 16.3103V10.3962" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M14.9404 1.52494C15.2765 1.18883 15.7324 1 16.2077 1C16.683 1 17.1389 1.18883 17.475 1.52494C17.8111 1.86105 17.9999 2.31691 17.9999 2.79225C17.9999 3.26758 17.8111 3.72345 17.475 4.05956L9.44871 12.0859L6.06921 12.9307L6.91409 9.55123L14.9404 1.52494Z" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                {/* <svg className="ml-2" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    deleteAddress(props.location.state.addressSelect.id)
                  }}
                >
                  <path d="M2.75 5.5H4.58333H19.25" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7.33337 5.49998V3.66665C7.33337 3.18042 7.52653 2.7141 7.87035 2.37028C8.21416 2.02647 8.68048 1.83331 9.16671 1.83331H12.8334C13.3196 1.83331 13.7859 2.02647 14.1297 2.37028C14.4736 2.7141 14.6667 3.18042 14.6667 3.66665V5.49998M17.4167 5.49998V18.3333C17.4167 18.8195 17.2236 19.2859 16.8797 19.6297C16.5359 19.9735 16.0696 20.1666 15.5834 20.1666H6.41671C5.93048 20.1666 5.46416 19.9735 5.12034 19.6297C4.77653 19.2859 4.58337 18.8195 4.58337 18.3333V5.49998H17.4167Z" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12.8334 10.0833V15.5833" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9.16663 10.0833V15.5833" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg> */}

              </div>
            </div>
          </div>

        </div>
      </div>

      <div style={{
        marginBottom: `${Number(window.screen.height) > 1000 ? "200px" : "100px"}`
      }}></div>

      <div className="footer-page-address">
        <div className={`${Number(window.screen.width) > 600 ? "container" : ""}`}>
          <div className="row no-pad">
            <div className="col-12">
              <p className='text-title font-weight-bold text-left' style={{ marginBottom: '0px' }}>หมายเหตุ: ของมูลค่าเกิน 1,000 บาทจะต้องแนบบัตรประชาชนสำหรับเพื่อออกหนังสือหักภาษี ณ ที่จ่าย กรุณาแนบสำเนาบัตรประชาชนพร้อมลายเซ็นสำเนาถูกต้องในระบบหรือส่งผ่านแอดไลน์ @fastscan</p>
            </div>
          </div>
          <div className="row no-pad">
            <div className="col-12">
              <FormGroup>
                <FormControlLabel className='text-title-policy' control={<GreenCheckbox checked={checked} onChange={(e) => handleCheckbox(e.target.checked)} />} label="ยินยอมในการเปิดเผยข้อมูลเพื่อแลกรางวัล" />
              </FormGroup>

            </div>
          </div>
          <div className="row no-pad">
            <div className="col-12">
              <button className="a-set btn"
                disabled={loadButton}
                onClick={() => redeem()}
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </div>
      </div>

      <OpenModalConsent
        disabled={loadButton}
        open={checkedConsent}
        handleClose={handleCheckboxModal}
        handleConfirm={handleCheckboxModal}
        title={'ยินยอมในการเปิดเผยข้อมูล'}
        content={'ข้าพเจ้าตกลงและยินยอมให้บริษัท BP Castrol เก็บรวบรวมและใช้ข้อมูลบัตรประจำตัวประชาชนของข้าพเจ้า เพื่อวัตถุประสงค์ในการออกใบกำกับภาษีตามกฎหมาย'}
        type={'typePopup'}
      />

      <Modal
        open={open}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-sm modal-dialog-centered w-75 m-auto">
          <div className="modal-content" style={{ borderRadius: '12px' }}>
            <div className="mt-3" style={{
              textAlign: "center"
            }}>
              <div className="container">

                <span style={{
                  fontFamily: "DB Heavent",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "34px",
                  lineHeight: "41px",
                  color: "#000000",
                }}>ยินดีด้วย!</span>
                <br />

                <img src={'/privilegeimage/congratulations.png'} style={{
                  height: "60%",
                  width: "50%",
                  maxHeight: "300px",
                  maxWidth: "300px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }} />

              </div>
            </div>

            <div className="center">
              <div className="redeem">
                <span className="text-redeem">
                  การแลกรับของรางวัลสำเร็จ
                </span>
              </div>
            </div>

            <div className="center mt-4 mb-3">
              <button className="btn button-text" onClick={() => gotohome()}>กลับสู่หน้าหลัก</button>
            </div>

          </div>
        </div>
      </Modal>

    </>
  )
}

export default Address
