import React, { useState, useEffect } from 'react'
import HeaderBackPoint from "../../components/HeaderBackPoint"
import { Link, useHistory } from "react-router-dom";
import { apiUrl } from '../../constants';
import { Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import OpenRewardModal from "../../components/OpenRewardModal"
import "./Other.scss";

const Other = () => {
  let token = localStorage.getItem("token")
  const cover_token = token ? JSON.parse(token) : ""
  let url = new URL(window.location.href)
  const tab = url.searchParams.get("tab");
  const history = useHistory();
  const [key, setKey] = useState('noti');
  const [newsData, setNewsData] = useState({});
  const [favoriteData, setFavoriteData] = useState({});
  const [historyData, setHistoryData] = useState({});

  const [showReward, setShowReward] = useState(false);
  const [dataReward, setDataReward] = useState();
  useEffect(() => {
    // console.log(tab);
    setKey(tab)
    getCategory(cover_token)
    getFavorite(cover_token)
    getHistory(cover_token)
  }, [])

  const getCategory = async (token) => {
    const apiUrls = `${apiUrl}/news?page=1&limit=1000`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      let event = []
      res.data.data.map((data, key) => {
        const date = new Date(data.updated_at)
        const dateTH = date.toLocaleString('th-TH', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
        let dataDupicate = Object.assign({}, data, { date_th: dateTH });
        event.push(dataDupicate)
      })
      setNewsData(event)

    }).catch((err) => {
      console.log(err);
    });
  };

  const getFavorite = async (token) => {
    const apiUrls = `${apiUrl}/favorite`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setFavoriteData(res.data)

    }).catch((err) => {
      console.log(err);
    });
  };

  const getHistory = async (token) => {
    const apiUrls = `${apiUrl}/privilege/history2?page=1&limit=1000`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      let event = []
      res.data.data.map((data, key) => {
        const date = new Date(data.updated_at)
        const dateTH = date.toLocaleString('th-TH', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
        let dataDupicate = Object.assign({}, data, { date_th: dateTH });
        event.push(dataDupicate)
      })
      // console.log('event', event);
      setHistoryData(event)

    }).catch((err) => {
      console.log(err);
    });
  };

  const gotodetail = (data) => {
    localStorage.setItem("id", JSON.stringify(data.id));
    history.push(`/Detail?token=${cover_token}&id=${data.id}`, data)
    localStorage.setItem("data_detail", JSON.stringify(data));
  }

  const toggleFavorite = async (id, type) => {
    const apiUrls = `${apiUrl}/favorite/${id}`;
    axios({
      method: 'delete',
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${cover_token}`,
      },
    }).then((res) => {
      getFavorite(cover_token)
    });
  };

  const handleClose = () => {
    setShowReward(false)
    setDataReward()
  }

  // console.log(news);
  return (
    <div className="other-page">
      <div className="title-header p-3">
        <HeaderBackPoint topic={'เมนูอื่นๆ'} path={`/privilege?token=${cover_token}`} />
      </div>

      <Tabs id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="noti" title="แจ้งเตือน">
          <div className="noti-tab container">
            {newsData.length > 0 ?
              newsData.map((data) => {
                return <div className="d-flex py-3 noti-list" key={data.id}>
                  <div className="col-8">
                    <span className="mb-1 font-weight-bold">{data.title}</span>
                    <span className="date d-block">{data.date_th} น.</span>
                    <p className="mb-1 content">{data.content}</p>
                  </div>
                  <div className="col-4 ">
                    <img className="pt-2 w-100" src={data.thumbnail}></img>
                  </div>
                </div>
              })
              :
              null
            }
          </div>
        </Tab>
        <Tab eventKey="favorite" title="ชื่นชอบ">
          <div className="favorite-tab container">
            <div className="row no-pad">
              {favoriteData.length > 0 ?
                favoriteData.map((data, key) => {
                  return <div
                    key={key}
                    className={`col-6 pt-2`}
                  >
                    {/* {String(data.favorite)} */}
                    <center className="bg">
                      <div className="top-left p-3"
                        onClick={() => toggleFavorite(data.id, data.favorite)}
                      >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: "#fee17b" }}>
                          <circle cx="10" cy="10" r="10" fill="currentColor" />
                          <path d="M15.9804 8.5416C15.9354 8.4079 15.8199 8.311 15.6811 8.28997L11.992 7.72511L10.3391 4.19774C10.2154 3.93409 9.78414 3.93409 9.6604 4.19774L8.00747 7.72511L4.31838 8.28997C4.17964 8.311 4.06414 8.40865 4.01915 8.5416C3.9749 8.6753 4.00865 8.82253 4.10689 8.92318L6.78727 11.6746L6.15355 15.564C6.1303 15.706 6.1903 15.8487 6.3073 15.9313C6.42429 16.014 6.57878 16.023 6.70478 15.9531L10.0001 14.1286L13.2955 15.9524C13.3525 15.9839 13.4147 15.9997 13.477 15.9997C13.5527 15.9997 13.6285 15.9764 13.693 15.9313C13.8107 15.8487 13.87 15.706 13.8467 15.564L13.213 11.6746L15.8934 8.92318C15.9916 8.82253 16.0254 8.67455 15.9804 8.5416Z" fill="#FEFEFE" />
                        </svg>
                      </div>
                      <div className="privilege-img"
                        onClick={() => gotodetail(data)}
                      >
                        <img className="w-75" src={data.deal_image} />
                      </div>
                      <div className="row no-pad bottom-right">
                        <div className="col d-flex align-items-center p-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="white" />
                            <path d="M13.5664 12.1172H4.48828V6.18359L9 11.3L13.5664 6.18359V12.1172Z" fill="white" />
                            <path d="M13.5664 6.18359L9 11.3V12.1172H13.5664V6.18359Z" fill="white" />
                            <path d="M4.48828 12.1172L9 5.17188L13.5664 12.1172H4.48828Z" fill="white" />
                            <path d="M9 5.17188V12.1172H13.5664L9 5.17188Z" fill="white" />
                          </svg>
                          <span className="text-point p-1">{data.point == 0 ? "ฟรี" : data.point}</span>
                        </div>
                      </div>


                    </center>

                    {/* <div>
                      <span className="text-type">
                        {data.topic}
                      </span>
                    </div> */}
                    <div>
                      <span className="text-discription">
                        {data.deal_name}
                      </span>
                    </div>
                    <div>
                      <span className="text-all">
                        <span className="text-reward">
                          {data.remain}
                        </span>
                        /{data.total} รางวัล
                      </span>
                    </div>
                  </div>

                })
                : <span className="w-100 text-center py-5">ไม่พบรายการชื่นชอบ</span>
              }
            </div>
          </div>

        </Tab>
        <Tab eventKey="history" title="ประวัติรับสิทธิ์">
          {/* <p>3</p> */}
          <div className="history-tab container">
            {historyData.length > 0 ?
              historyData.map((data, key) => {
                return <div
                  className="list-redeem row py-2 mx-1"
                  key={data.id}
                  onClick={() => {
                    if (data.is_delivery == 1) {
                      history.push(`/Shipping?token=${cover_token}&id=${data.id}`, { data: data })
                    }
                    else {
                      setShowReward(true)
                      setDataReward(data)
                    }
                  }}
                >
                  <div className="w-25">
                    <img src={data.deal_image} />
                  </div>

                  <div className="col-6">
                    <span className="list-name">{data.deal_name}{data.deal_model_name ? ` (${data.deal_model_name})` : null}</span>

                    {data.is_delivery == 1 &&
                      <div className="font-size-s txt-status">
                        สถานะจัดส่ง:
                        <span className="d-block text-success">{data.status_th}</span>
                      </div>
                    }

                    <div className="font-size-s txt-status">{data.date_th} น.</div>


                  </div>


                  <div className="w-25">
                    <span className={`d-block font-size-s txt-point ${data.total_deal_point == 0 ? 'text-success' : 'text-danger'}`}>{data.total_deal_point == 0 ? "ฟรี" : "-" + data.total_deal_point + " คะแนน"} </span>
                    <strong className="mt-2 d-block txt-point text-danger">{data.qty} รางวัล</strong>
                  </div>
                </div>
              })
              : null
            }
          </div>
        </Tab>
      </Tabs>
      {
        (showReward && dataReward) && <OpenRewardModal
          open={showReward}
          privilege={dataReward}
          handleClose={handleClose}
        />
      }
    </div >
  )
}

export default Other