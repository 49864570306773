import React, { useState, useEffect } from 'react'
import "./mycss.scss";
import liff from '@line/liff'
import axios from "axios";
import { apiUrl } from '../../constants';

const Index = () => {
  const [consent1, setConsent1] = useState(false)
  const [consent2, setConsent2] = useState(false)
  const [lineState, setLineState] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    lineAuth()
  }, [])

  const lineAuth = async () => {
    setIsLoading(true)
    await liff.init({ liffId: process.env.REACT_APP_LINE_LIFF_ID })
      .then(async (data) => {
        if (liff.isLoggedIn()) {
          let getProfile = await liff.getProfile();
          setLineState(getProfile)
          setIsLoading(false)
        } else {
          liff.login();
        }
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }

  const submit = async () => {
    setIsLoading(true)

    await axios
      .post(`${apiUrl}/consent`, { line_id: lineState.userId })
      .then((res) => {
        setIsLoading(false)
        liff.closeWindow();
      })
      .catch((err) => {
        setIsLoading(false)
      });
  }


  return (
    <div className="privilege-page">
      <div className="container">

        <div className="head-point-privilege-page">
          <div className="card-privilege-page">
            <div className="row no-pad">
              <div className="col-12">
                <span className="text-topic">การให้ความยินยอม</span>
              </div>
            </div>
            <div className="text-discription">
              <span>
                <p>
                  ข้าพเจ้า ผู้ลงนามข้างท้ายแบบฟอร์มนี้ ขอยืนยันว่าข้าพเจ้าได้อ่านและเข้าใจในรายละเอียดข้างต้นเกี่ยวกับการเก็บรวบรวม ใช้ เปิดเผย โอน หรือประมวลผลข้อมูลส่วนบุคคลของข้าพเจ้าแล้ว ตลอดจนรับทราบสิทธิต่าง ๆ ที่ข้าพเจ้าพึงมีในฐานะเจ้าของข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
                  และกฎหมายอื่น ๆ ที่เกี่ยวข้อง
                </p>

                <p>
                  ข้าพเจ้า ผู้ลงนามข้างท้ายแบบฟอร์มนี้ ขอยืนยันว่าข้าพเจ้าได้อ่านและเข้าใจในรายละเอียดข้างต้นเกี่ยวกับการเก็บรวบรวม ใช้ เปิดเผย โอน หรือประมวลผลข้อมูลส่วนบุคคลของข้าพเจ้าแล้ว ตลอดจนรับทราบสิทธิต่าง ๆ ที่ข้าพเจ้าพึงมีในฐานะเจ้าของข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
                  และกฎหมายอื่น ๆ ที่เกี่ยวข้อง
                </p>

                <p>
                  ข้าพเจ้าทราบดีว่าการที่บริษัทฯ ไม่ได้รับความยินยอมโดยชัดแจ้งจากข้าพเจ้าจะมีผลต่อบริษัทฯ ในการเสนอสินค้าและ/หรือบริการแก่ข้าพเจ้า ตลอดจนทราบดีว่าข้าพเจ้าสามารถขอถอนความยินยอมที่ข้าพเจ้าได้ให้ไว้แก่บริษัทฯ ได้ทุกเมื่อ และบริษัทฯ จะปฏิบัติต่อข้อมูลส่วนบุคคลของข้าพเจ้าด้วยความเคารพ
                </p>
              </span>
            </div>
            <div className="text-topic mb-2">ข้อมูลส่วนบุคคลทั่วไป</div>
            <div className="form-check checkbox mb-2">
              <input className="form-check-input" type="checkbox" checked={consent1}
                onChange={() => setConsent1(!consent1)} id="consent1" />
              <label className="form-check-label" for="consent1">
                ข้าพเจ้าประสงค์จะให้ความยินยอมแก่บริษัทฯ ในการเก็บรวบรวม ใช้ เปิดเผย โอน และประมวลผลข้อมูลส่วนบุคคลทั่วไป
                ของข้าพเจ้า
              </label>
            </div>
            <div className="text-topic mb-2">ข้อมูลส่วนบุคคลที่ละเอียดอ่อน</div>
            <div className="form-check checkbox mb-2">
              <input className="form-check-input" type="checkbox"
                checked={consent2}
                onChange={() => setConsent2(!consent2)} id="consent2" />
              <label className="form-check-label" for="consent2">
                ข้าพเจ้าประสงค์จะให้ความยินยอมโดยชัดแจ้งแก่บริษัทฯ ในการเก็บรวบรวม ใช้ เปิดเผย โอน และประมวลผลข้อมูล
                ส่วนบุคคลที่ละเอียดอ่อนของข้าพเจ้า
              </label>
            </div>

            <div className="text-discription">
              <span>
                <p>บริษัทฯ จะไม่สามารถดำเนินการใดๆ เพื่อให้บรรลุวัตถุประสงค์ตามที่กล่าวมาได้ หากบริษัทฯ ไม่ได้รับข้อมูลส่วนบุคคลข้างต้น<br />
                  &bull; การก่อตั้ง พัฒนา จัดการ ดำรงไว้ เก็บรักษา หรือเข้าตกลงในเรื่องการสิ้นสุดซึ่งความสัมพันธ์ทางธุรกิจระหว่างท่านกับบริษัทฯ<br />
                  &bull; การยืนยันตัวตนเพื่อเข้าร่วมโปรแกรมที่จัดทำขึ้น<br />
                  &bull; การจัดเตรียม ดำเนินการ ประเมิน หรือกำหนดคำขอหรือข้อเรียกร้องที่เกี่ยวข้องกับสินค้าและ/หรือบริการของบริษัทฯ<br />
                  &bull; การจัดทำเอกสารภาษี หัก ณ ที่จ่าย<br />
                  &bull; การจัดเตรียม และดำเนินการส่งของรางวัล ให้แก่ร้านค้า</p>
              </span>
            </div>

          </div>

        </div>
        {consent1 && consent2 &&
          <div className='text-center'>
            <button
              type="button"
              className="btn"
              onClick={() => submit()}
              style={{
                fontFamily: "DB Heavent",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "22px",
                lineHeight: "28px",
                color: "white",
                backgroundColor: "#00833b"
              }}>
              ยืนยัน
            </button>
          </div>
        }
      </div>
      <div className="mb-5"></div>
    </div>
  )
}

export default Index
