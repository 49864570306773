import React, { useEffect, useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const GreenCheckbox = withStyles({
    root: {
        color: '#009343',
        '&$checked': {
            color: '#009343',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const htmlString = `
<div style="text-align: center;font-size:12pt;"><strong>ประกาศนโยบายความเป็นส่วนตัวและแบบฟอร์มการให้ความยินยอม</strong></div>
<div style="text-align: center;font-size:12pt; "><strong>สำหรับลูกค้าทุติยภูมิของ Castrol (คาสตรอล ออโต้ เซอร์วิส และ คาสตรอล ไบค์พ้อยท์)</strong></div>
<p style="text-align: justify;border-bottom:solid #000000 0.5pt;"><br></p>
<h1 style="text-align: center;"><strong><span style="font-size:12pt;">ประกาศนโยบายความเป็นส่วนตัว</span></strong></h1>
<p style="text-align: justify;"><span style="font-size:12pt;">บริษัท บีพี - คาสตรอล (ประเทศไทย) จำกัด (&ldquo;</span><strong><span style="font-size:12pt;">บริษัทฯ</span></strong><span style="font-size:12pt;">&rdquo;) มีความจำเป็นต้องเก็บรวบรวม ใช้ เปิดเผย โอน และประมวลผลข้อมูลส่วนบุคคลของท่าน ทั้งที่เป็นข้อมูลส่วนบุคคลทั่วไป (&ldquo;</span><strong><span style="font-size:12pt;">ข้อมูลส่วนบุคคลทั่วไป</span></strong><span style="font-size:12pt;">&rdquo;) และข้อมูลส่วนบุคคลที่ละเอียดอ่อน (&ldquo;</span><strong><span style="font-size:12pt;">ข้อมูลส่วนบุคคลที่ละเอียดอ่อน</span></strong><span style="font-size:12pt;">&rdquo;) (รวมเรียกว่า &ldquo;</span><strong><span style="font-size:12pt;">ข้อมูลส่วนบุคคล</span></strong><span style="font-size:12pt;">&rdquo;) ดังต่อไปนี้</span></p>
<p style="text-align: justify;"><span style="font-size:12pt;">ข้อมูลส่วนบุคคลทั่วไป</span><span style="font-size:12pt;">&nbsp; &nbsp;&nbsp;</span><span style="font-size:12pt;">&nbsp; &nbsp;&nbsp;</span><span style="font-size:12pt;">&nbsp; &nbsp;&nbsp;</span></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
      <span style="font-size:12pt;text-align: justify;">ชื่อและนามสกุล</span>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
       <span style="font-size:12pt;text-align: justify;">หมายเลขติดต่อ</span>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
       <span style="font-size:12pt;text-align: justify;">หมายเลขบัตรประจำตัวประชาชน/หมายเลขประจำตัวผู้เสียภาษี</span>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
       <span style="font-size:12pt;text-align: justify;">ที่อยู่</span>
    </li>

</ul>
<p style="text-align: justify;"><span style="font-size:12pt;">ข้อมูลส่วนบุคคลที่ละเอียดอ่อน</span><span style="font-size:12pt;">&nbsp; &nbsp;&nbsp;</span><span style="font-size:12pt;">&nbsp; &nbsp;&nbsp;</span><span style="font-size:12pt;">&nbsp; &nbsp;&nbsp;</span></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
      <span style="font-size:12pt;text-align: justify;">สำเนาบัตรประจำตัวประชาชน (ที่ปรากฏข้อมูลศาสนาและกลุ่มเลือด)</span>
    </li>

</ul>
<p style="text-align: justify;"><strong><span style="font-size:12pt;">วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล</span></strong></p>
<p style="text-align: justify;"><span style="font-size:12pt;">บริษัทฯ จะเก็บรวบรวม ใช้ เปิดเผย โอน และประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้</span></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
      <span style="font-size:12pt;text-align: justify;">การก่อตั้ง พัฒนา จัดการ ดำรงไว้ เก็บรักษา หรือเข้าตกลงในเรื่องการสิ้นสุดซึ่งความสัมพันธ์ทางธุรกิจระหว่างท่านกับบริษัทฯ</span>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
       <span style="font-size:12pt;text-align: justify;">การยืนยันตัวตนเพื่อเข้าร่วมโปรแกรมที่จัดทำขึ้น</span>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
      <span style="font-size:12pt;text-align: justify;">การจัดเตรียม ดำเนินการ ประเมิน หรือกำหนดคำขอหรือข้อเรียกร้องที่เกี่ยวข้องกับสินค้าและ/หรือบริการของบริษัทฯ</span>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <span style="font-size:12pt;text-align: justify;">การจัดทำเอกสารภาษี หัก ณ ที่จ่าย</span>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
       <span style="font-size:12pt;text-align: justify;">การจัดเตรียม และดำเนินการส่งของรางวัล ให้แก่ร้านค้า</span>
    </li>
</ul>
<p style="text-align: justify;"><strong><span style="font-size:12pt;">ความจำเป็นในการประมวลผลข้อมูลส่วนบุคคล</span></strong></p>
<p ><span style="font-size:12pt;">บริษัทฯ จำเป็นต้องได้รับข้อมูลส่วนบุคคลของท่านเพื่อดำเนินการตามต่อวัตถุประสงค์ดังกล่าวข้างต้น</span></p>

<p style="text-align: justify;"><strong><span style="font-size:12pt;">ผลกระทบหากบริษัทฯ ไม่ได้รับข้อมูลส่วนบุคคลของท่าน</span></strong></p>
<p style="text-align: justify;"><span style="font-size:12pt;">บริษัทฯ จะไม่สามารถดำเนินการใดๆ เพื่อให้บรรลุวัตถุประสงค์ตามที่กล่าวมาได้ หากบริษัทฯ ไม่ได้รับข้อมูลส่วนบุคคลข้างต้น</span></p>

<p style="text-align: justify;"><strong><span style="font-size:12pt;">การเปิดเผยหรือโอนข้อมูลส่วนบุคคล</span></strong></p>
<p style="text-align: justify;"><span style="font-size:12pt;">บริษัทฯ จะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นความลับอย่างเคร่งครัด และจะไม่เปิดเผยหรือโอนข้อมูลดังกล่าวให้แก่บุคคลภายนอก เว้นแต่บริษัทฯ จะได้รับความยินยอมโดยชัดแจ้งจากท่านหรือได้รับคำสั่งจากเจ้าหน้าที่ผู้มีอำนาจ</span></p>

<p style="text-align: justify;"><span style="font-size:12pt;">เพื่อวัตถุประสงค์ดังกล่าวข้างต้น บริษัทฯ จะเปิดเผยและ/หรือโอนข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกซึ่งรวมถึง</span></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <span style="font-size:12pt;text-align: justify;">บริษัทในเครือและในกลุ่ม</span>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <span style="font-size:12pt;text-align: justify;">คู่ค้าหรือพันธมิตรทางธุรกิจ ผู้ให้บริการ หรือผู้จำหน่ายสินค้าที่ได้รับอนุญาต</span>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <span style="font-size:12pt;text-align: justify;">ตัวแทนฯ ที่ได้รับการแต่งตั้งจากบริษัท เพื่อจัดส่งของรางวัลให้แก่ร้านค้า</span>
    </li>
</ul>
<p style="text-align: justify;"><span style="font-size:12pt;">ในกรณีที่ต้องมีการโอนข้อมูลส่วนบุคคลออกนอกประเทศไทย บริษัทฯ จะดำเนินการโดยเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (&ldquo;</span><strong><span style="font-size:12pt;">พรบ. คุ้มครองข้อมูลส่วนบุคคล</span></strong><span style="font-size:12pt;">&rdquo;) ซึ่งรวมถึงแต่ไม่จำกัดเพียงกฎกระทรวง ประกาศ การตีความ หรือกฎระเบียบอื่น ๆ ที่ออกภายใต้พระราชบัญญัตินี้ และหากมีความจำเป็น บริษัทฯ จะปฏิบัติตามหลักเกณฑ์ แนวปฏิบัติหรือวิธีการป้องกันที่เกี่ยวข้องกับการรักษาความปลอดภัยของข้อมูลและการโอนไปยังต่างประเทศตามที่ปฏิบัติเป็นการทั่วไปภายใต้ระเบียบการคุ้มครองข้อมูลทั่วไป (General Data Protection Regulation) ซึ่งเป็นที่รู้จักโดยทั่วไปว่า GDPR</span></p>
<p style="text-align: justify;"><strong><span style="font-size:12pt;">ระยะเวลาเก็บรักษาข้อมูล</span></strong></p>
<p style="text-align: justify;"><span style="font-size:12pt;">บริษัทฯ จะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นระยะเวลา 3 ปี หลังจากที่ความสัมพันธ์ระหว่างท่านกับเราสิ้นสุดลง โดยบริษัทฯ ขอรับรองว่าข้อมูลส่วนบุคคลของท่านจะได้รับการเก็บรักษาไว้ในสถานที่ที่ปลอดภัย ซึ่งเฉพาะผู้ได้รับอนุญาตเท่านั้นที่จะสามารถเข้าถึงข้อมูลนั้นได้ และบริษัทฯ จะทำลายข้อมูลส่วนบุคคลของท่านเป็นการถาวรทันทีที่สิ้นสุดระยะเวลาเก็บรักษาข้อมูล</span></p>
<p style="text-align: justify;"><strong><span style="font-size:12pt;">สิทธิของท่านเกี่ยวกับข้อมูลส่วนบุคคลภายใต้พรบ. คุ้มครองข้อมูลส่วนบุคคล</span></strong></p>
<p style="text-align: justify;"><span style="font-size:12pt;">ท่านมีสิทธิทุกประการตามที่พรบ. คุ้มครองข้อมูลส่วนบุคคลได้กำหนดไว้ เว้นแต่ในบางสถานการณ์ที่บริษัทฯ อาจจำเป็นต้องปฏิเสธคำขอใช้สิทธิของท่าน</span></p>
<p style="text-align: justify;"><span style="font-size:12pt;">และที่สำคัญคือท่านมีสิทธิที่จะถอนความยินยอมของท่าน&nbsp;</span><span style="color:#0d0d0d;font-size:12pt;">ทั้งนี้&nbsp;</span><span style="font-size:12pt;">หากท่านมีความประสงค์ให้บริษัทฯ ทำการเปลี่ยนแปลงใด ๆ เกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่าน ท่านสามารถแจ้งให้เราทราบได้ทุกเวลา</span></p>
<p style="text-align: justify;"><span style="font-size:12pt;">ท่านสามารถศึกษารายละเอียดเพิ่มเกี่ยวกับสิทธิของท่านและขั้นตอนการขอถอนความยินยอมได้จาก</span><strong><span style="font-size:12pt;">นโยบายความเป็นส่วนตัว</span></strong><span style="font-size:12pt;">ของบริษัทฯ ที่ปรากฏบนเว็บไซต์ [</span><a href="https://www.castrol.com/th_th/thailand/home/privacy-statement.html"><u><span style="color:#0563c1;font-size:13.999999999999998pt;">https://www.castrol.com/th_th/thailand/home/privacy-statement.html</span></u></a><span style="font-size:12pt;">]&nbsp;</span></p>
<p style="text-align: justify;"><strong><span style="font-size:12pt;">ติดต่อเรา</span></strong></p>
<p style="text-align: justify;"><span style="font-size:12pt;">หากท่านประสงค์ใด ๆ จะขอข้อมูลเพิ่มเติม หรือใช้สิทธิใด ๆ ของท่าน ท่านสามารถติดต่อ</span></p>
<p style="text-align: justify;"><span style="font-size:12pt;">Email Address</span><span style="font-size:12pt;">&nbsp; &nbsp;&nbsp;</span><span style="font-size:12pt;">castrolfastscan@bp.com</span></p>
<p style="text-align: justify;border-bottom:solid #000000 0.5pt;"><br></p>`
const OpenModalConsent = (props) => {
    const [checkedPersonal, setCheckedPersonal] = useState(false);
    const [checkeSensitive, setCheckeSensitive] = useState(false);

    const handleClose = () => {
        setCheckedPersonal(false)
        setCheckeSensitive(false)
        props.handleClose(false)
    }
    return (
        <>
            <Dialog
                open={props.open}
            >
                <DialogContent>

                    <div dangerouslySetInnerHTML={{ __html: htmlString }} />
                    <p style={{ textAlign: "justify", fontSize: "12pt" }}><strong><span>แบบฟอร์มการให้ความยินยอม</span></strong></p>
                    <p style={{ textAlign: "", fontSize: "12pt" }}><span>ข้าพเจ้า ผู้ลงนามข้างท้ายแบบฟอร์มนี้ ขอยืนยันว่าข้าพเจ้าได้อ่านและเข้าใจในรายละเอียดข้างต้นเกี่ยวกับการเก็บรวบรวม ใช้ เปิดเผย โอน หรือประมวลผลข้อมูลส่วนบุคคลของข้าพเจ้าแล้ว ตลอดจนรับทราบสิทธิต่าง ๆ ที่ข้าพเจ้าพึงมีในฐานะเจ้าของข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 และกฎหมายอื่น ๆ ที่เกี่ยวข้อง</span></p>
                    <p style={{ textAlign: "", fontSize: "12pt" }}><span>ข้าพเจ้าเข้าใจข้อความและวัตถุประสงค์ต่าง ๆ ในการเก็บรวบรวม ใช้ เปิดเผย โอน หรือประมวลผลข้อมูลส่วนบุคคลของข้าพเจ้า ที่บริษัทฯ ระบุไว้ในประกาศนโยบายความเป็นส่วนตัวของบริษัทฯ โดยตลอดแล้ว ตามรายละเอียดข้างต้น จึงขอแสดงเจตนาเกี่ยวกับการให้ความยินยอมโดยการใส่เครื่องหมายลงในช่องที่กำหนดไว้ด้านล่างนี้</span></p>
                    <p style={{ textAlign: "", fontSize: "12pt" }}><span>ข้าพเจ้าทราบดีว่าการที่บริษัทฯ ไม่ได้รับความยินยอมโดยชัดแจ้งจากข้าพเจ้าจะมีผลต่อบริษัทฯ ในการเสนอสินค้าและ/หรือบริการแก่ข้าพเจ้า ตลอดจนทราบดีว่าข้าพเจ้าสามารถขอถอนความยินยอมที่ข้าพเจ้าได้ให้ไว้แก่บริษัทฯ ได้ทุกเมื่อ และบริษัทฯ จะปฏิบัติต่อข้อมูลส่วนบุคคลของข้าพเจ้าด้วยความเคารพ</span></p>
                    <p style={{ textAlign: "justify", fontSize: "12pt" }}><strong><span>ข้อมูลส่วนบุคคลทั่วไป</span></strong></p>
                    <FormGroup>
                        <FormControlLabel className='text-title-consent' control={<GreenCheckbox checked={checkedPersonal} onChange={(e) => setCheckedPersonal(e.target.checked)} />} label="ข้าพเจ้าประสงค์จะให้ความยินยอมแก่บริษัทฯ ในการเก็บรวบรวม ใช้ เปิดเผย โอน และประมวลผลข้อมูลส่วนบุคคลทั่วไปของข้าพเจ้า" />
                    </FormGroup>
                    <p style={{ textAlign: "justify", fontSize: "12pt" }}><strong><span>ข้อมูลส่วนบุคคลที่ละเอียดอ่อน</span></strong></p>
                    <FormGroup>
                        <FormControlLabel className='text-title-consent' control={<GreenCheckbox checked={checkeSensitive} onChange={(e) => setCheckeSensitive(e.target.checked)} />} label="ข้าพเจ้าประสงค์จะให้ความยินยอมโดยชัดแจ้งแก่บริษัทฯ ในการเก็บรวบรวม ใช้ เปิดเผย โอน และประมวลผลข้อมูลส่วนบุคคลที่ละเอียดอ่อนของข้าพเจ้า" />
                    </FormGroup>


                    <p style={{ textAlign: "", fontSize: "12pt" }}><span>บริษัทฯ จะไม่สามารถดำเนินการใดๆ เพื่อให้บรรลุวัตถุประสงค์ตามที่กล่าวมาได้ หากบริษัทฯ ไม่ได้รับข้อมูลส่วนบุคคลข้างต้น</span></p>
                    <ul>
                        <li style={{ listStyleType: "disc", fontSize: "12pt" }}>
                            <span style={{ textAlign: "justify", fontSize: "12pt" }}>การก่อตั้ง พัฒนา จัดการ ดำรงไว้ เก็บรักษา หรือเข้าตกลงในเรื่องการสิ้นสุดซึ่งความสัมพันธ์ทางธุรกิจระหว่างท่านกับบริษัทฯ</span>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "12pt" }}>
                            <span style={{ textAlign: "justify", fontSize: "12pt" }}>การยืนยันตัวตนเพื่อเข้าร่วมโปรแกรมที่จัดทำขึ้น</span>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "12pt" }}>
                            <span style={{ textAlign: "justify", fontSize: "12pt" }}>การจัดเตรียม ดำเนินการ ประเมิน หรือกำหนดคำขอหรือข้อเรียกร้องที่เกี่ยวข้องกับสินค้าและ/หรือบริการของบริษัทฯ</span>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "12pt" }}>
                            <span style={{ textAlign: "justify", fontSize: "12pt" }}>การจัดทำเอกสารภาษี หัก ณ ที่จ่าย</span>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "12pt" }}>
                            <span style={{ textAlign: "justify", fontSize: "12pt" }}>การจัดเตรียม และดำเนินการส่งของรางวัล ให้แก่ร้านค้า</span>
                        </li>
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button color="primary"
                        className="btn"
                        style={{
                            fontFamily: "DB Heavent",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "22px",
                            // color: "#949494"
                            color: "#000000",
                        }}
                        onClick={() => handleClose()}
                    >
                        ยกเลิก
                    </Button>
                    <Button
                        className="btn"
                        disabled={!checkedPersonal || !checkeSensitive}
                        variant="contained"
                        style={{
                            fontFamily: "DB Heavent",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "22px",
                            lineHeight: "28px",
                            color: "#FFF",
                            backgroundColor: "#009343"
                        }}
                        onClick={() => props.handleConfirm()}
                    >
                        ยอมรับ
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
export default OpenModalConsent
