import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import ModalMui from '@material-ui/core/Modal';
// import { getValidateRedeem, getCountRedeemHistory, getDealDetail, getSurvey } from '@/services/getService';
import { apiUrl } from '../constants';
import axios from "axios";

export const OpenSurveyModal = (props) => {
  const {
    dataSurvey,
    dataPrivilege,
    dataUser,
    token,
    handleCloseModel,
    dataRedeem,
    setDataReedeem,
    setShowPrivilege,
    setStateL,
    setHoursMinSecs,
    errorPri,
    setErrorPri,
    showError,
    setShowError,
    isSMS,
    currentPopupID = 0,
    setShowPopup = (x) => { /*console.log('test', x)*/ }
  } = props;

  const regexOTP = new RegExp('^[0-9]{6}$');
  const listRef = useRef([])
  // const [showRedemptionButton, setShowRedemptionButton] = useState(true);
  const [stateS, setStateS] = useState({
    loading: false,
  });
  const [stateP, setStateP] = useState({
    loading: false,
  });

  const [state, setState] = useState({});
  const [errors, seterror] = useState({});
  const [blueSurvey, setBlueSurvey] = useState(false);
  const [showSMS, setShowSMS] = useState(false);
  const [countDownSeconds, setCountDownSeconds] = useState({ seconds: 300 });
  const [errorOTP, setErrorOTP] = useState('');
  const [otpData, setOtpData] = useState({ mobile: '', refCode: '' });
  const [showSurvey, setSurvey] = useState(false);
  const [showScans, setshowScans] = useState(false);
  const [showAccept, setAccept] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showComple, setshowComple] = useState(false);
  const [imagePreview, setImagePreview] = useState({});
  const [loading, setLoading] = useState(false);
  const [isUnmounted, setisUnmounted] = useState(false);
  const [showConfirmSy, setShowConfirmSy] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [completeModel, setCompleteModel] = useState(false);

  const [errorText, setErrorText] = useState('');

  useEffect(() => { setErrorText(errorPri) }, [state.error, errorPri]);

  const handleCloseshowConfirmSy = () => {
    setShowConfirmSy(false)
    setSurvey(true)
  }

  // const handleshowScans = async (data) => {
  //   setShowPopup(currentPopupID)
  //   if (data) {
  //     setisUnmounted(true);
  //     const datas = await getDealDetail(data);
  //     if (datas.code == undefined) {
  //       const Validate = await getValidateRedeem(datas.privilege_id != undefined ? datas.privilege_id : datas.id);
  //       if (Validate.success) {
  //         setDataPV(datas);
  //         setShowNewPass(true);
  //         setshowScans(false);
  //       } else {
  //         setisUnmounted(false);
  //         // ValidatePrivilege(true, Validate);
  //         setShowNewPass(true);
  //         setshowScans(false);
  //       }
  //     } else {
  //       setisUnmounted(false);
  //       // ValidatePrivilege(true, datas);
  //       setShowNewPass(true);
  //       setshowScans(false);
  //     }
  //   }
  // };

  const handleCloseScans = () => {
    setShowPopup(currentPopupID)
    setshowScans(false);
  };


  // const ValidatePrivilege = async (show, msg) => {
  //   console.log('msg')
  //   console.log(msg)
  //   let text = '';
  //   if (msg.code == '400') {
  //     text = t('global:400');
  //   }
  //   if (msg.errorCode == '300') {
  //     text = t('global:300');
  //   }
  //   if (msg.errorCode == '301') {
  //     text = t('global:301');
  //   }
  //   if (msg.errorCode == '302') {
  //     text = t('global:302');
  //   }
  //   if (msg.errorCode == '303') {
  //     text = t('global:303');
  //   }
  //   if (msg.errorCode == '304') {
  //     text = t('global:304');
  //   }
  //   if (msg.errorCode == '305') {
  //     text = t('global:305');
  //   }
  //   if (msg.errorCode == '306') {
  //     text = t('global:306');
  //   }
  //   if (msg.errorCode == '307') {
  //     text = t('global:307');
  //   }
  //   if (msg.errorCode == '203') {
  //     text = t('global:203');
  //   }
  //   if (msg.errorCode == '202') {
  //     text = t('global:202');
  //   }
  //   if (msg.errorCode == '201') {
  //     text = t('global:201');
  //   }
  //   if (!showRedemptionButton) {
  //     setShowPrivilege(false);
  //   }
  //   if (msg.code == '400' && msg.errorCode == undefined) {
  //     setShowError(true);
  //   }
  //   setErrorPri(text);
  //   setAccept(false);
  //   setLoading(false);
  //   setStateP({ loading: false });
  // };
  const filledOrNot = (dataSurvey, state, stateError) => {
    // console.log(state)
    let checkValid = dataSurvey.surveyQuestions.filter((s) => {
      if (s.is_require) {

        if (!state.hasOwnProperty(s.id)) return true;
        else if (!state[s.id] || (state[s.id] && stateError[s.id])) return true;
      }
      return false;
    });
    // console.log('checkValid', checkValid, state, stateError)
    return checkValid.length > 0 ? false : true;
  };

  const handleExpPrivilegeClose = () => {
    setShowPopup(currentPopupID)
    setErrorPri('');
    setShowError(false);
  };

  const ConfirmSy = (type) => {
    if (!filledOrNot(dataSurvey, state, errors)) {
      setShowAll(true)
    }

    if (type != 'SMS' && filledOrNot(dataSurvey, state, errors)) {
      setShowAll(false)
      setSurvey(false)
      setShowConfirmSy(true)
    } else if (filledOrNot(dataSurvey, state, errors)) {
      setShowAll(false)
      setSurvey(false)
      setShowConfirmSy(true)
    }
  }

  const postSurvey = async (data) => {
    var config = {
      method: 'post',
      url: apiUrl + '/survey',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    const response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error.response.data;
      });

    return response;
  };

  const handleSurvey = async (data, qty) => {
    setStateS({ loading: true });
    var error = [];
    let qus = await dataSurvey.surveyQuestions.sort((a, b) => a.display_order - b.display_order);
    for (const item of qus) {
      if (state[item.id] == '' && item.question_type == 'TEXTBOX') {
        error.push('กรุณาใส่ข้อความ');
      } else if (state[item.id] == '' && item.question_type == 'CHECKBOX') {
        error.push('กรุณาใส่เลือก');
      } else if (state[item.id] == '' && item.question_type == 'IMAGE') {
        error.push('กรุณาใส่เพิ่มไฟล์');
      } else {
        error.push('');
      }
    }
    seterror({ ...error });
    let checkError = Object.values(errors).filter((msg) => msg != '');
    if (checkError.length == 0) {
      let answers = [];
      let qus = await dataSurvey.surveyQuestions.sort((a, b) => a.display_order - b.display_order);
      let checkWhite = true
      console.log(dataUser)
      for (const item of qus) {
        console.log(item, state, state[item.id])
        if (item.question_type == 'IMAGE') {
          if (state[item.id] != undefined) {
            // let img = await Upload(state[item.id]);
            answers.push({
              question_id: item.id,
              deal_id: dataPrivilege.id,
              user_id: dataUser.id,
              answer: 'img.data.filename',
            });
          } else {
            // let img = await Upload(state[item.id]);
            answers.push({
              question_id: item.id,
              deal_id: dataPrivilege.id,
              user_id: dataUser.id,
              answer: '',
            });
          }

        } else {
          answers.push({
            question_id: item.id,
            deal_id: dataPrivilege.id,
            user_id: dataUser.id,
            answer: String(state[item.id]),
          });
        }
      }
      let data = { answers };
      if (checkWhite) {
        let postSurveys = await postSurvey(data).then(async (res) => {
          setshowComple(true);
          if (res.code == undefined) {

            // if ((dataPrivilege.auto_approve || dataPrivilege.ano_auto_approve)) {
            //   if ((!dataPrivilege.is_lucky && !dataPrivilege.is_delivery)) {
            //     // const Redeem = await getRedeem(dataPrivilege.privilege_id != undefined ? dataPrivilege.privilege_id : dataPrivilege.id, undefined, '');
            //     // Redeem[0].deal_obj.privilege_redeem_id = Redeem[0].privilege_redeem_id
            //     // Redeem[0].redeemCheck = true
            //     // setDataPV(Redeem[0].deal_obj)
            //     setShowConfirmSy(false)
            //     setAccept(true);
            //     console.log('in')
            //     // handlePrivilegeShow(dataPV.length == 0 ? dataRedeem.deal_obj : Redeem[0].deal_obj);
            //   } else {
            //     // const Redeem = await getRedeem(dataPV.privilege_id != undefined ? dataPV.privilege_id : dataPV.id, undefined, '');
            //     // Redeem[0].deal_obj.privilege_redeem_id = Redeem[0].privilege_redeem_id
            //     // Redeem[0].redeemCheck = true
            //     // setDataPV(Redeem[0].deal_obj)
            //     setShowConfirmSy(false)
            //     setshowComple(true);
            //   }
            // } else {
            //   setShowConfirmSy(false)
            //   setshowComple(true);
            // }
            // setStateS({ loading: false });
            // return res;
          }

        });
        // if (postSurveys.code == undefined) {

        //   setSurvey(false);
        //   setShowConfirmSy(false)
        //   setStateS({ loading: false });
        // }
        //  else {
        //   seterror({ ...errors, 0: 'เกิดข้อผิดพลาดกรุณาลองใหม่' });
        //   setStateS({ loading: false });
        // }
      } else {
        setStateS({ loading: false });
      }
    }
  };
  const gotohome = () => {
    // setCompleteModel(t);
    handleCloseModel()
  }

  const handleNewPass = async (data, e_type, e_name) => {
    setErrorPri('');
    setLoading(true);
    setStateP({ loading: true });
    // const GroupdealAxios = await getDealDetail(data.privilege_id != undefined ? data.privilege_id : data.id);
    // const ValidateAxios = await getValidateRedeem(data.privilege_id != undefined ? data.privilege_id : data.id);


    // const Groupdeal = GroupdealAxios;
    // const Validate = ValidateAxios

    const Groupdeal = null;
    const Validate = null

    // if ((Route.pathname == "/announcement" || Route.pathname == "/history") && Groupdeal.auto_approve) {
    //   let checkRegister = await getValidateRegisterAndApprove(data.privilege_id != undefined ? data.privilege_id : data.id, Groupdeal.survey_id);
    //   Groupdeal.register = checkRegister.register
    //   Groupdeal.checkRegister = checkRegister
    //   Groupdeal.checkRegister.approved = true
    // } else {
    //   let checkRegister = await getValidateRegisterAndApprove(data.privilege_id != undefined ? data.privilege_id : data.id, Groupdeal.survey_id);
    //   Groupdeal.register = checkRegister.register
    //   Groupdeal.checkRegister = checkRegister
    // }

    if (Groupdeal.code == undefined) {
      if (Validate.success) {
        Groupdeal.waiting_approval = data.waiting_approval;
        // if (Groupdeal.survey_id != null && Route.pathname != "/announcement" && Route.pathname != "/history") {
        //   Groupdeal.redeemCheck = await checkCountPVL(data.privilege_id != undefined ? data.privilege_id : data.id, Groupdeal);
        //   Groupdeal.bypassRedeem = true;
        // } else {
        //   Groupdeal.redeemCheck = false
        //   Groupdeal.bypassRedeem = true;
        //   Groupdeal.waiting_approval = data.waiting_approval;
        //   Groupdeal.expire_used_at = data.expire_used_at;
        // }

        // setDataPV(Groupdeal);
        // if (data.is_lucky) {
        //   setShowNewPass(true);
        // } else {
        //   setShowNewPass(true);
        // }

        if (Groupdeal.survey_id != null) {
          // const Survey = await getSurvey(Groupdeal.survey_id);
          // const dataSurveys = [];
          // Survey.data.surveyQuestions.map((item, i) => {
          //   dataSurveys.push('');
          // });
          // setState({ OTP: '', ...dataSurveys });
          // seterror({ ...dataSurveys });
          // setDataSurvey(Survey.data);
          // // setIsSMS(SMSOrNot(Survey.data.surveyQuestions));
          // setLoading(true);
          // setStateP({ loading: false });
        } else {
          setLoading(true);
          setStateP({ loading: false });
        }
      } else {

        // setDataPV(Groupdeal);
        // ValidatePrivilege(true, Validate);
        // setShowNewPass(true);
        setLoading(true);
        setStateP({ loading: false });
      }
      setLoading(false);
      setStateP({ loading: false });
    } else {
      // ValidatePrivilege(true, Groupdeal);
      // setShowNewPass(true);
      setLoading(false);
      setStateP({ loading: false });
    }
  };

  const handlerChange = (e) => {
    let temp = null
    let temp_err = null
    const { name, value, checked, files, id } = e.target;
    if (e.target.type == 'file') {
      try {
        const objectUrl = URL.createObjectURL(files[0]);
        setImagePreview((nextState) => ({ ...nextState, [name]: objectUrl }));
        setState((nextState) => ({
          ...nextState,
          [name]: files[0],
        }));
        temp = { ...state, [name]: files[0] }
        seterror((nextState) => ({
          ...nextState,
          [name]: '',
        }));
        temp_err = { ...errors, [name]: '', }
      } catch {

      }

    } else if (e.target.type == 'checkbox') {
      let data = id.split('-')[1];
      // console.log(checked)
      if (checked) {
        if (state[data]) {
          let newState = state[data];
          newState = newState + ',' + value;
          setState((nextState) => ({
            ...nextState,
            [data]: newState,
          }));
          temp = { ...state, [data]: newState }
        } else {
          setState((nextState) => ({
            ...nextState,
            [data]: value,
          }));
          temp = { ...state, [data]: value }
        }
      } else {
        let newState = state[data].split(',');
        newState = newState.filter((s) => s != value);
        setState((nextState) => ({
          ...nextState,
          [data]: newState.toString(),
        }));
        temp = { ...state, [data]: newState.toString() }
      }
      seterror((nextState) => ({
        ...nextState,
        [name]: '',
      }));
      temp_err = { ...errors, [name]: '', }
    } else if (e.target.type == 'text') {
      if (name != 'OTP') {
        const question = dataSurvey.surveyQuestions.find((item) => item.id == name);
        const regex = new RegExp(question.regex);
        const test = regex.test(value);
        setState((nextState) => ({
          ...nextState,
          [name]: value,
        }));
        temp = { ...state, [name]: value }
        if (test == false) {
          seterror((nextState) => ({
            ...nextState,
            [name]: question.warning,
          }));
          temp_err = { ...errors, [name]: question.warning, }
        } else {
          seterror((nextState) => ({
            ...nextState,
            [name]: '',
          }));
          temp_err = { ...errors, [name]: '' }
        }
      } else {
        const test = regexOTP.test(value);
        setState((nextState) => ({
          ...nextState,
          [name]: value,
        }));
        temp = { ...state, [name]: value }
        setErrorOTP({});
        seterror((nextState) => ({
          ...nextState,
          [name]: !test ? 'ใส่ตัวเลข 6 ตัว และใส่ได้เฉพาะ ตัวเลขเท่านั้น' : '',
        }));
      }
    } else {

      setState((nextState) => ({
        ...nextState,
        [name]: value,
      }));
      temp = { ...state, [name]: value }
      seterror((nextState) => ({
        ...nextState,
        [name]: '',
      }));
      temp_err = { ...errors, [name]: '' }
    }

    if (filledOrNot(dataSurvey, temp, temp_err)) {
      setBlueSurvey(true)
    } else {
      setBlueSurvey(false)
    }

  };



  const handleCloseSurvey = () => {
    // setShowPopup(currentPopupID)
    // setImagePreview({});
    // setStateP({ loading: true })
    // setErrorPri('');
    // setState({});
    // seterror({});
    // setSurvey(false);
    // handleNewPass(dataPV);
    // setShowNewPass(true);
  };


  // const handlePrivilegeShow = async (data) => {
  //   setLoading(true);
  //   setStateS({ loading: true });
  //   let idSpecial = null;
  //   let Redeem = []
  //   // if ((data.survey_id != null && (!data.is_lucky && !data.is_delivery)) && !data.expire_used_at) {
  //   if ((data.survey_id != null) && !data.expire_used_at) {
  //     Redeem = [data]
  //     Redeem[0].redeemCheck = true
  //   } else {
  //     Redeem = await getRedeem(data.privilege_id != undefined ? data.privilege_id : data.id, undefined, idSpecial);
  //   }
  //   // console.log('redeem', Redeem)
  //   try {
  //     Redeem[0].remain = Redeem[0].deal_obj.actualCodeRemaining
  //   } catch {
  //     // console.log('sss')
  //   }
  //   console.log('Redeem', Redeem)
  //   if (!Redeem.privilege_code && Redeem.code == undefined) {
  //     if (!Redeem[0].used_at) {
  //       let addTime = { success: true };
  //       if ((data.survey_id == null || data.auto_approve == true || data.auto_approve == false || data.waiting_approval == 1) && (!data.is_lucky && !data.is_delivery || data.is_lucky && !data.is_delivery)) {
  //         addTime = await PostAddTimeExp(Redeem[0].privilege_redeem_id);
  //       }

  //       if (addTime.success) {
  //         if ((data.survey_id != null && data.auto_approve != true) && data.waiting_approval == 0 && dataPV.ano_auto_approve != undefined) {
  //           setshowComple(true);
  //           setShowPrivilege(false);
  //           setAccept(false);
  //           setDataReedeem(Redeem[0]);
  //           setLoading(false);
  //           setShowNewPass(false);
  //           setShowConfirm(false);
  //           setStateS({ loading: false });
  //         } else {
  //           if (!data.is_lucky || (data.is_lucky && !data.is_delivery)) {
  //             let time = moment(new Date(addTime.data.expire_used_at), 'HH:mm:ss: A').diff(moment(), 'seconds');
  //             setHoursMinSecs({ seconds: time });

  //             setShowNewPass(false);
  //             setAccept(false);
  //             if (data.survey_id != null) {
  //               setDataReedeem(addTime.data);
  //             } else {
  //               setDataReedeem(Redeem[0]);
  //             }
  //             setShowNewPass(false);
  //             setShowPrivilege(true);
  //             setStateS({ loading: false });
  //             setStateL({ loading: false });
  //             setLoading(false);

  //           } else {
  //             setshowComple(true);
  //             setShowConfirmSy(false)
  //             setShowPrivilege(false);
  //             setAccept(false);
  //             setDataReedeem(Redeem[0]);
  //             setLoading(false);
  //             setShowNewPass(false);
  //             setShowConfirm(false);
  //             setStateS({ loading: false });
  //           }

  //         }
  //       }
  //     } else {
  //       let time = moment(new Date(Redeem[0].expire_used_at), 'HH:mm:ss: A').diff(moment(), 'seconds');
  //       setHoursMinSecs({ seconds: time > 0 ? time : 0 });
  //       setShowPrivilege(true);
  //       setAccept(false);
  //       if (data.survey_id != null) {
  //         Redeem[0].deal_obj = dataRedeem.deal_obj
  //         setDataReedeem(Redeem[0]);
  //       } else {
  //         setDataReedeem(Redeem[0]);
  //       }
  //       setShowNewPass(false);
  //       setStateS({ loading: false });
  //       setLoading(false);
  //     }
  //   } else {
  //     ValidatePrivilege(true, Redeem);
  //     setShowNewPass(true);
  //     setLoading(false);
  //     setStateS({ loading: false });
  //   }
  // };

  const checkCountPVL = async (deal_id, data) => {
    // if (data.survey_id != null) {
    //   let PVL = await getCountRedeemHistory(data.privilege_id != undefined ? data.privilege_id : data.id)
    //   return PVL.exist
    // } else {
    //   return false
    // }
  }

  const Spinner = () => {
    const content = (
      <div className="loading-pages" style={{ top: '0', zIndex: '10' }}>
        <svg width="80" height="80" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" fill="none" stroke="#000" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(275.845 50 50)">
            <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite" />
          </circle>
        </svg>
      </div>
    );
    return content;
  };

  const checkLabelHeight = (text) => {
    return String(text).length > 53 ? 94 : 50
  }

  return (
    <>
      {dataSurvey && (
        <Modal className="Modal_survey" show={showComple ? false : true} onHide={() => handleCloseModel()} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <div
                dangerouslySetInnerHTML={{
                  __html: dataSurvey.survey_name,
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="survey_content"
              dangerouslySetInnerHTML={{
                __html: dataSurvey.survey_desc,
              }}
            />
            <section className="group_account_register box_layout">
              {dataSurvey.surveyQuestions
                .sort((a, b) => a.display_order - b.display_order)
                .map((type, i) => {
                  if (type.question_type == 'TEXTBOX') {
                    return (
                      <div className="group_input_container">
                        <div className="group_input_box" >
                          <label name={"label_" + type.id} ref={el => listRef.current[i] = el} >
                            {type.question_text}
                            <span>{type.is_require ? '*' : ''}</span>
                          </label>
                          <input
                            type={type.question_type}
                            name={type.id}
                            className={`lineborderInput ${(errors.hasOwnProperty(type.id) && errors[type.id] != '') || (type.is_require && showAll) ? 'error' : ''}`}
                            placeholder={type.place_holder}
                            defaultValue={state[type.id]}
                            style={{ height: checkLabelHeight(type.question_text) }}
                            onChange={(e) => handlerChange(e)}
                          />
                        </div>

                        {errors[type.id] != '' && <label className="error-msg">{errors[type.id]}</label>}
                      </div>
                    );
                  }
                  if (type.question_type == 'IMAGE') {
                    return (
                      <div className="group_input_container">
                        <div className="group_input_upload">
                          <label className="title_upload">{type.question_text} <span>{type.is_require ? '*' : ''}</span></label>
                          <div className="group_zone" >
                            {imagePreview[type?.id] != null ? (
                              <label htmlFor={type.id} style={{ padding: '0' }}>
                                <img height="145px" alt="preview img" src={imagePreview[type.id]} />
                              </label>
                            ) : (
                              <label htmlFor={type.id}>
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M17.4167 10.0833H4.58341C4.07715 10.0833 3.66675 10.4937 3.66675 10.9999C3.66675 11.5062 4.07715 11.9166 4.58341 11.9166H17.4167C17.923 11.9166 18.3334 11.5062 18.3334 10.9999C18.3334 10.4937 17.923 10.0833 17.4167 10.0833Z" fill="#3F4253" />
                                    <path d="M11.9167 17.4166L11.9167 4.58329C11.9167 4.07703 11.5063 3.66663 11.0001 3.66663C10.4938 3.66663 10.0834 4.07703 10.0834 4.58329L10.0834 17.4166C10.0834 17.9229 10.4938 18.3333 11.0001 18.3333C11.5063 18.3333 11.9167 17.9229 11.9167 17.4166Z" fill="#3F4253" />
                                  </svg>
                                </span>
                                <span>{'อัปโหลดรูป'}</span>
                              </label>
                            )}
                            <input accept="image/*" type="file" name={type.id} id={type.id} className="upload" onChange={(e) => handlerChange(e)} />
                          </div>
                        </div>

                        {errors[type.id] != '' && <label className="error-msg">{errors[type.id]}</label>}
                      </div>
                    );
                  }
                  if (type.question_type == 'CHECKBOX') {
                    if (type.choice) {
                      return (
                        <div>
                          <div className="group_input_container">
                            <label className="title_upload">
                              {type.question_text}
                              <span>{type.is_require ? '*' : ''}</span>
                            </label>
                            {errors[type.id] != '' && <label className="error-msg">{errors[type.id]}</label>}
                          </div>
                          <div className="group_input_container ">
                            {type.choice.split(',').map((choices, i) => {
                              return (
                                <div key={i} className="group_input_checkbox">
                                  <label className="group_checkbox">
                                    <div className="title_checkbox">{choices}</div>
                                    <input type="checkbox" id={`${choices}-${type.id}-${i}`} checked={state[type.id] != undefined && state[type.id].includes(choices)} value={choices} onChange={(e) => handlerChange(e)} />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="group_input_container">
                          <div className="group_input_checkbox">
                            <label className="group_checkbox">
                              <div className="title_checkbox">{type.question_text} <span>{type.is_require ? '*' : ''}</span></div>
                              <input type="checkbox" id={`${type.id}-${type.id}-silger`} defaultChecked={state[type.id]} name={type.id} onChange={(e) => handlerChange(e)} />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          {errors[type.id] != '' && <label className="error-msg">{errors[type.id]}</label>}
                        </div>
                      );
                    }
                  }
                  if (type.question_type == 'RADIO') {
                    return (
                      <div>
                        <div className="group_input_container">
                          <label className="title_upload">{type.question_text} <span>{type.is_require ? '*' : ''}</span></label>
                          {errors[type.id] != '' && <label className="error-msg">{errors[type.id]}</label>}
                        </div>
                        <div className="group_input_container" onChange={(e) => handlerChange(e)}>
                          {type.choice.split(',').map((choices, i) => {
                            return (
                              <div key={i} className="group_input_checkbox margin_bottom_5px">
                                <label className="group_checkbox">
                                  <div className="title_checkbox">{choices}</div>
                                  <input type="radio" name={type.id} value={choices} checked={state[type.id] === choices} defaultChecked={state[type.id]} />
                                  <span className="checkmark radio"></span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                })}

              <div className="group_btn_form">
                <button
                  className={`btn_layout ${blueSurvey ? 'btn_blue' : 'btn_gray'}`}
                  onClick={() => {
                    handleSurvey(dataPrivilege)
                    ConfirmSy()
                  }}
                //disabled={!blueSurvey}
                >
                  {isSMS ? <> {'ถัดไป'}</> : <> {'ส่งคำตอบ'}</>}
                </button>
                <div className="btn_layout btn_cc" onClick={() => handleCloseSurvey()}>
                  {/* {t('global:101020')} */}
                </div>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      )}

      {stateP.loading && (
        <div className="group-mod height-100" style={{ position: 'absolute', zIndex: 99999 }}>
          {Spinner()}
        </div>
      )}

      <div className={`group-modal height-100 ${stateS.loading ? '' : 'hide'}`} style={{ zIndex: '2000' }}>
        <ModalMui
          open={showComple}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-sm modal-dialog-centered w-75 m-auto">
            <div className="modal-content" style={{ borderRadius: '12px' }}>
              <div className="mt-3" style={{
                textAlign: "center"
              }}>
                <div className="container">

                  <span style={{
                    fontFamily: "DB Heavent",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "34px",
                    lineHeight: "41px",
                    color: "#000000",
                  }}>ยินดีด้วย!</span>
                  <br />

                  <img src={'/privilegeimage/congratulations.png'} style={{
                    height: "60%",
                    width: "50%",
                    maxHeight: "300px",
                    maxWidth: "300px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }} />

                </div>
              </div>

              <div className="center">
                <div className="redeem">
                  <span className="text-redeem">
                    คุณได้รับคะแนนเพิ่ม 10 คะแนน
                  </span>
                </div>
              </div>

              <div className="center mt-4 mb-3">
                <button className="btn button-text" onClick={() => gotohome()}>กลับสู่หน้าหลัก</button>
              </div>


            </div>
          </div>
        </ModalMui>
      </div>

      <Modal show={showScans} onHide={handleCloseScans} className="modal-container custom-map-modal" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        {/* <Modal.Body>{!isUnmounted ? <QrReader delay={2000} onError={handleError} onScan={handleshowScans} style={{ width: '100%' }} className="qr-theme" /> : Spinner()}</Modal.Body> */}
      </Modal>

      <Modal key={8} show={showError} onHide={() => handleExpPrivilegeClose()} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="center-height sm-modal error_modal">
        <Modal.Body>
          <div className="group-modal text-center">
            <div className="center-height">
              <div className="title title-privilege" dangerouslySetInnerHTML={{ __html: 'เกิดข้อผิดพลาด' }} />
              <div className="title title-privilege">{errorPri} </div>
              <div></div>
              <div className="group-button">
                <div onClick={() => handleExpPrivilegeClose()}>ปิด</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
};
export default OpenSurveyModal;
