import React from 'react';
import Modal from '@material-ui/core/Modal';

export const PopupBanner = ({
  dataPopup,
  loading,
  currentStep,
  setCurrentStep,
  handleNewPass,
  handlesetNews,
  handleDetail
}) => {

  const setLocalClose = (id) => {
    localStorage.setItem(id, JSON.stringify(false));
    alreadyShow = false
    setCurrentStep(currentStep + 1)
  };


  const handleImageThumbnail = (popup) => {
    if (popup.thumbnail_th == null) {
      return '/static/images/default/GG_654X1074.jpg';
    } else {
      return popup.thumbnail_th;
    }
  };

  const handleGoDetail = (data) => {
    setLocalClose(data.id)
    handleDetail(data)
  }
  // console.log(currentShow)
  var alreadyShow = false
  return (
    <>
      {dataPopup.map((popup) => {
        const local = JSON.parse(localStorage.getItem(popup.id)) == null;
        // console.log(currentShow)
        if (!alreadyShow && local) {
          alreadyShow = true
          return (
            <Modal
              open
              disablePortal
              disableEnforceFocus
              disableAutoFocus
            >
              <div className="modal-dialog modal-dialog-centered w-75 m-auto">
                <div className="modal-content" style={{ borderRadius: '12px' }}>
                  <div className="mt-3" style={{
                    textAlign: "center"
                  }}>
                    <span style={{
                      fontFamily: "DB Heavent",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "25px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}>ยินดีด้วย!</span>
                    <div className="container">
                      <div
                        key={popup.id}
                        id={popup.id}
                        className={`modal_popup ${popup.order_item}`}
                      >
                        <>
                          <div className={`img`}>
                            <div style={{ position: 'relative' }}>
                              <img src={handleImageThumbnail(popup)} alt="images" style={{ width: "100%", objectFit: "contain", objectPosition: "center", paddingTop: "5px" }} onClick={() => (!loading ? handleDetail(popup) : '')}></img>
                            </div>
                            <br></br>
                            <span style={{
                              fontFamily: "DB Heavent",
                              fontStyle: "normal",
                              fontWeight: "bold",
                              fontSize: "25px",
                              lineHeight: "28px",
                              color: "#000000",
                            }}>{popup.content_th}</span>
                          </div>
                        </>
                      </div>
                      {/* );
                    }
                  })} */}

                    </div>

                  </div>
                  <svg height="12px" width="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1">

                    <line className="dashed" x1="0"
                      x2="100%" y1="20" y2="20" />
                  </svg>
                  <div className="btn-group">
                    <button
                      type="button" className="btn"
                      style={{
                        fontFamily: "DB Heavent",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "22px",
                        color: "#949494"
                      }}
                      onClick={() => setLocalClose(popup.id)}
                    >ไว้ภายหลัง</button>
                    <button
                      // disabled={props.disabled}
                      type="button" className="btn"
                      style={{
                        fontFamily: "DB Heavent",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "22px",
                        lineHeight: "28px",
                        color: "#000000",
                      }}
                      onClick={() => handleGoDetail(popup)}
                    >แลกเลย</button>
                  </div>
                </div>
              </div>
            </Modal>
          );
        }
      })}

    </>
  );
};
