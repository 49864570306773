import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import "./Address.scss";
import HeaderBackPoint from "../../components/HeaderBackPoint"
import { apiUrl } from "../../constants";
import axios from "axios";
import Swal from "sweetalert2";
const CreateAddress = (props) => {

  let token = localStorage.getItem("token")
  const cover_token = token ? JSON.parse(token) : ""
  let id = localStorage.getItem("id")
  const cover_id = id ? JSON.parse(id) : ""
  const history = useHistory();

  //mode 0 == create mode 1 == edit
  const [mode, setmode] = useState(0);

  const [errorvalidate, seterrorvalidate] = useState(false);
  const num_validate = new RegExp(/^[0-9\b]+$/);

  const [name, setname] = useState();
  const [namevalidate, setnamevalidate] = useState(false);
  const [detail, setdetail] = useState();
  const [detailvalidate, setdetailvalidate] = useState(false);
  const [phone, setphone] = useState();
  const [phonevalidate, setphonevalidate] = useState(false);

  const [post_code, setpost_code] = useState();
  const [post_codevalidate, setpost_codevalidate] = useState(false);
  const [districtData, setdistrictData] = useState();

  const [locations, setlocations] = useState([]);
  const [locations_district, setlocations_district] = useState([]);

  const [province_id, setprovince_id] = useState();
  const [province, setprovince] = useState([]);
  const [provincevalidate, setprovincevalidate] = useState(false);
  const [district_id, setdistrict_id] = useState();
  const [district, setdistrict] = useState();
  const [districtvalidate, setdistrictvalidate] = useState(false);
  const [sub_district_id, setsub_district_id] = useState();
  const [sub_district_validate, setsub_district_validate] = useState(false);
  const [sub_district_selete, setsub_district_selete] = useState([]);

  useEffect(() => {
    // console.log(props.location.state)
    setmode(props.location.state.mode)
    if (Number(props.location.state.mode) === 1) {
      setname(props.location.state.data.name)
      setdetail(props.location.state.data.detail)
      setpost_code(props.location.state.data.post_code)
      setphone(props.location.state.data.phone)
      setprovince_id(props.location.state.data.province_id)
      setdistrict_id(props.location.state.data.district_id)
      setsub_district_id(props.location.state.data.sub_district_id)
      const apiUrls = `${apiUrl}/location/zipcode/${props.location.state.data.post_code}`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }).then((res) => {
        var dis_data = [];
        var province_data = [];

        if (res.data.length > 0) {
          res.data.map((data, key) => {
            if (data.province_id === props.location.state.data.province_id) {
              dis_data.push({
                code: data.code,
                id: data.id,
                name_en: data.name_en,
                name_th: data.name_th,
                province_id: data.province_id,
                subDistricts: data.subDistricts
              });
            }
          });

          res.data.map((data, key) => {
            let check_province = province_data.find((p) => Number(p.id) === Number(data.province.id));
            if (!check_province) {
              province_data.push(data.province);
            }
          });
          setprovince(province_data)
          setdistrictData(dis_data.length === 1 ? dis_data[0] : {})
          setlocations(dis_data)
          setlocations_district(dis_data)
          if (dis_data.length === 1) {
            setdistrict(dis_data)
          }
          let item = dis_data.find((l) => Number(l.id) === Number(props.location.state.data.district_id));
          setsub_district_selete(item.subDistricts)
        }
      })

    }
  }, [])

  const next = () => {
    // console.log(name)
    // console.log(phone)
    // console.log(post_code)
    // console.log(province_id)
    // console.log(district_id)
    // console.log(sub_district_id)
    if (!name) {
      setnamevalidate(true)
    } else if (!phone) {
      setphonevalidate(true)
    } else if (!post_code) {
      setpost_codevalidate(true)
    } else if (!province_id) {
      setprovincevalidate(true)
    } else if (!district_id) {
      setdistrictvalidate(true)
    } else if (!sub_district_id) {
      setsub_district_validate(true)
    } else {

      let name_full = name.split(" ")

      let address_data = {
        location_name: detail,
        first_name: name_full[0],
        last_name: name_full[1],
        address1: detail,
        address2: "",
        sub_district_id: Number(sub_district_id),
        district_id: Number(district_id),
        province_id: Number(province_id),
        post_code: Number(post_code),
        phone: phone
      }

      console.log(address_data)
      if (Number(props.location.state.mode) === 1) {
        const apiUrls = `${apiUrl}/client/address/${Number(props.location.state.data.id)}`;
        const resultAll = axios({
          method: "put",
          url: apiUrls,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${cover_token}`,
          },
          data: address_data
        }).then((res) => {

          history.goBack();
        }).catch(function (error) {
          if (error.response) {
            Swal.fire({
              title: "ผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              confirmButtonText: "ตกลง",
              confirmButtonColor: '#00833b',
            });
          }
        });

      } else {
        const apiUrls = `${apiUrl}/client/address`;
        const resultAll = axios({
          method: "post",
          url: apiUrls,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${cover_token}`,
          },
          data: address_data

        }).then((res) => {
          history.goBack();
        }).catch(function (error) {
          if (error.response) {
            Swal.fire({
              title: "ผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              confirmButtonText: "ตกลง",
              confirmButtonColor: '#00833b',
            });
          }
        });

      }
    }

  }


  const locationSearch = (post_code_input) => {
    if (post_code_input.length === 5) {
      const apiUrls = `${apiUrl}/location/zipcode/${post_code_input}`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }).then((res) => {
        var province_data = [];
        if (res.data.length > 0) {
          res.data.map((data, key) => {
            let check_province = province_data.find((p) => Number(p.id) === Number(data.province.id));
            if (!check_province) {
              province_data.push(data.province);
            }
          });
          setprovince(province_data)
          if (province_data.length === 1) {
            getDistricts(province_data[0].id, res)
          }
        }

      }).catch((err) => {
        history.push(`/Error`);
      });
    } else {
      setdistrictData(null)
      setlocations([])
      setlocations_district([])
      setprovince_id(null)
      setprovince([])
      setdistrict_id(null)
      setdistrict(null)
      setsub_district_id(null)
      setsub_district_selete([])
    }
  }

  const getDistricts = (id_pro, res) => {
    var dis_data = [];
    if (res.data.length > 0) {
      res.data.map((data, key) => {
        if (data.province_id === id_pro) {
          dis_data.push({
            code: data.code,
            id: data.id,
            name_en: data.name_en,
            name_th: data.name_th,
            province_id: data.province_id,
            subDistricts: data.subDistricts
          });
        }
      });

      setdistrictData(dis_data.length === 1 ? dis_data[0] : {})
      setlocations(dis_data)
      setlocations_district(dis_data)
      setprovince_id(id_pro)
      if (dis_data.length === 1) {
        setdistrict(dis_data)
        setdistrict_id(dis_data[0].id)
        setsub_district_selete(dis_data[0].subDistricts)
        if (dis_data[0].subDistricts.length === 1) {
          setsub_district_id(dis_data[0].subDistricts[0].id)
        }
      }
    }
  }

  const handleProvinceChange = (province_id) => {
    if (province_id) {
      setprovince_id(province_id)
      getDistricts(Number(province_id))
    }
  }
  const getSubDistricts = (district_id) => {
    if (locations.length > 0) {
      let item = locations.find((l) => Number(l.id) === Number(district_id));
      setdistrict(item)
      setdistrict_id(district_id)
      setsub_district_selete(item ? item.subDistricts : "")
      if (item) {
        if (item.subDistricts.length === 1) {
          setsub_district_id(item.subDistricts[0].id)
        }
      }
    }
  }

  const handleSubDistricts = (sub_district_id) => {
    if (sub_district_id) {
      setsub_district_id(sub_district_id)
    } else {
      setsub_district_id("")
    }
  }

  return (
    <>
      <div className="address-page">
        <HeaderBackPoint
          topic={mode === 0 ? "สร้างที่อยู่" : "แก้ไขที่อยู่"}
          // mypoint={mypoint} 
          // add={"+ เพิ่มที่อยู่"}
          path={`/Address?token=${cover_token}&id=${cover_id}`}
        // path2={`/CreateAddress?token=${cover_token}&id=${cover_id}`}
        />
      </div>
      <svg height="11px" width="100%"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1">

        <line className="dashed" x1="0"
          x2="100%" y1="20" y2="20" />
      </svg>
      <div className="address-page-body container">

        <div className="container mt-2">
          <div className="form-group">
            <label htmlFor="formGroupExampleInput" className="text-form font-weight-bold">ชื่อ-นามสกุล</label>
            <input type="text" className="form-control input-address-form" id="formGroupExampleInput"
              placeholder="ชื่อ-นามสกุล"
              value={name}
              onChange={(e) => setname(e.target.value)} />
            {name === "" ?
              <div className="text-form" style={{ color: "red" }}>โปรดกรอกชื่อ-นามสกุล</div>
              : null
            }
          </div>


          <div className="form-group">
            <label htmlFor="formGroupExampleInput" className="text-form font-weight-bold">บ้านเลขที่</label>
            <input type="text" className="form-control input-address-form" id="formGroupExampleInput"
              value={detail}
              onChange={(e) => setdetail(e.target.value)}
              placeholder="รายละเอียด" />
            {/* {detail === "" ?
              <div className="text-form" style={{ color: "red" }}>โปรดกรอกรายละเอียดที่อยู่</div>
              : null
            } */}
          </div>

          <div className="form-group">
            <label htmlFor="formGroupExampleInput" className="text-form font-weight-bold">รหัสไปรษณีย์</label>
            <input type="tel" className="form-control input-address-form"
              maxLength="5"
              value={post_code}
              inputMode="numeric"
              id="formGroupExampleInput" placeholder="รหัสไปรษณีย์"
              onChange={(e) => {
                const regex = /^[0-9]{0,5}$/;
                let check_post_code = regex.test(`${e.target.value}`)
                // console.log(check)
                if (check_post_code) {
                  // console.log(post_code)
                  setpost_code(e.target.value)
                  locationSearch(e.target.value)
                } else {
                  // console.log(post_code)
                  setpost_code(post_code ? post_code : "")
                }
              }}
            />
            {post_code === "" ?
              <div className="text-form" style={{ color: "red" }}>โปรดกรอกรหัสไปรษณีย์</div>
              : null
            }
          </div>

          <div className="form-group">
            <label htmlFor="exampleFormControlSelect1" className="text-form font-weight-bold">จังหวัด</label>
            {province.length > 0 ? (
              <select
                name="province"
                className="form-control input-address-form"
                value={province_id}
                onChange={(e) => handleProvinceChange(e.target.value)}
              >

                {province.length > 1 ? (
                  <>
                    <option value="">
                      {"เลือก"}
                    </option>
                    {province.map((data, key) => {
                      return (
                        <option key={key} value={data.id}>
                          {data.name_th}
                        </option>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {province.map((data, key) => {
                      return (
                        <option key={key} value={data.id}>
                          {data.name_th}
                        </option>
                      );
                    })}
                  </>
                )}

              </select>
            ) : (
              <select
                name="province"
                className="form-control input-address-form"
              >
                <option value="">
                  {"เลือก"}
                </option>
              </select>
            )}

            {province_id === "" ?
              <div className="text-form" style={{ color: "red" }}>โปรดเลือกจังหวัด</div>
              : null
            }

          </div>

          <div className="form-group">
            <div className="row">
              <div className="col">
                <label htmlFor="exampleFormControlSelect1" className="text-form font-weight-bold">เขต/อำเภอ</label>

                <select
                  name="district"
                  className="form-control input-address-form"
                  value={district_id}
                  onChange={(e) => getSubDistricts(e.target.value)}
                >
                  {locations.length === 1 ? (
                    <>
                      <option value={districtData.id}>
                        {districtData.name_th}
                      </option>
                    </>
                  ) : (
                    <>
                      <option value="">
                        {"เลือก"}
                      </option>

                      {locations_district.map(
                        (data, key) => {
                          return (
                            <option key={key} value={data.id}>
                              {data.name_th}
                            </option>
                          );
                        }
                      )}
                    </>
                  )}
                </select>
                {district_id === "" ?
                  <div className="text-form" style={{ color: "red" }}>โปรดเลือกเขต/อำเภอ</div>
                  : null
                }
              </div>
              <div className="col">
                <label htmlFor="exampleFormControlSelect1" className="text-form font-weight-bold">ตำบล/แขวง</label>
                <select
                  name="sub_district"
                  className="form-control input-address-form"
                  value={sub_district_id}
                  onChange={(e) => handleSubDistricts(e.target.value)}

                >
                  {sub_district_selete.length === 1 ?
                    <>
                      <option value={sub_district_selete[0].id}>
                        {sub_district_selete[0].name_th}
                      </option>
                    </>
                    :
                    <>
                      <option value="">
                        {"เลือก"}
                      </option>
                      {sub_district_selete
                        ? sub_district_selete.map((data, key) => {
                          return (
                            <option key={key} value={data.id}>
                              {data.name_th}
                            </option>
                          );
                        })
                        : null}
                    </>
                  }
                </select>
                {sub_district_id === "" ?
                  <div className="text-form" style={{ color: "red" }}>โปรดเลือกตำบล/แขวง</div>
                  : null
                }
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="formGroupExampleInput" className="text-form font-weight-bold">หมายเลขโทรศัพท์</label>
            <input type="tal" className="form-control input-address-form no-arrow" id="formGroupExampleInput"
              maxLength="10"
              inputMode="numeric"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => {
                const regex = /^[0-9]{0,10}$/;
                let check = regex.test(`${e.target.value}`)
                // console.log(check)
                if (check) {
                  // console.log(phone)
                  setphone(e.target.value)
                } else {
                  // console.log(phone)
                  setphone(phone ? phone : "")
                }
              }}
              placeholder="หมายเลข" />

            {phone === "" ?
              <div className="text-form" style={{ color: "red" }}>
                โปรดกรอกหมายเลขโทรศัพท์
              </div>
              : ""
            }
            {phone ?
              <>
                {phone.length < 9 ?
                  <div className="text-form" style={{ color: "red" }}>
                    โปรดกรอกหมายเลขโทรศัพท์ให้ครบ 10 หลัก
                  </div>
                  : ""
                }
                {!num_validate.test(phone) ?
                  <div className="text-form" style={{ color: "red" }}>
                    โปรดกรอกหมายเลขโทรศัพท์ให้ถูกต้อง
                  </div>
                  : ""}
              </>
              : ""
            }


          </div>

        </div>

      </div>

      <div style={{
        marginBottom: `${Number(window.screen.height) > 1000 ? "200px" : "100px"}`
      }}></div>

      <div className="footer-page-address">
        <div className={`${Number(window.screen.width) > 600 ? "container" : ""}`}>
          <div className="row no-pad">
            <div className="col-12">
              {name &&
                phone &&
                post_code &&
                !post_codevalidate &&
                province_id &&
                district_id &&
                sub_district_id &&
                phone.length > 9 ?
                <button className="a-set btn"
                  onClick={() => next()}
                >
                  ยืนยัน
                </button>
                : <button className="a-set-un btn"
                >
                  ยืนยัน
                </button>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateAddress
