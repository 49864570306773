/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import { Tabs, Tab } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import { useClipboard } from 'use-clipboard-copy';
import CountDownTimer from './CountDownTimer';
const OpenRewardModal = (props) => {
    console.log(props.privilege.expire_at)
    const clipboard = useClipboard();
    const [isExpire, setIsExpire] = useState(false);
    const copyTextToClipboard = (text) => {
        copy(text);
    }
    const totalExpireSec = new Date(props.privilege?.expire_at)
    const a = new Date(); // Current date now.
    const totalSec = Math.round((totalExpireSec - a) / 1000); // Difference in milliseconds.
    
    const handleExpire = () => {
        setIsExpire(true)
    }
    return (
        <>
            <Modal
                open={props.open}
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                className="modal-barcode"
            >
                <div className="modal-dialog modal-dialog-centered w-90 m-auto text-center">
                    <div className="modal-content" style={{ borderRadius: '12px' }}>
                        <div className='mt-3' style={{
                            fontFamily: "DB Heavent",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "25px",
                            lineHeight: "28px",
                            color: "#000000",
                            padding: "5px"
                        }}>
                            <p>
                                {props?.privilege?.deal_name}
                            </p>
                        </div>
                        <div className="group-modal ">
                            <div className="group-tab-code">
                                <Tabs defaultActiveKey="password" id="uncontrolled-tab-example" className="mb-3">
                                    <Tab eventKey="password" title={'รหัส'}>
                                        <div className="group-code ">
                                            <div className="code-center coppyCode">
                                                {props?.privilege?.privilege_code}
                                                <input className="hide" ref={clipboard.target} value={props?.privilege?.privilege_code} readOnly />
                                                <div>
                                                    <a className="row" style={{ padding: '0 10px', display: 'contents' }} onClick={() => {
                                                        copyTextToClipboard(props?.privilege?.privilege_code);
                                                    }}>
                                                        <div style={{ display: 'content' }}>
                                                            คัดลอก
                                                        </div>{' '}

                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="barcode" title={'บาร์โค้ด'} >
                                        <div className="group-code">
                                            <div className="code-center">
                                                <Barcode value={props?.privilege?.privilege_code} width={2} height={70} renderer={"img"} />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="qrcode" title={'คิวอาร์โค้ด'} >
                                        <div className="group-code">
                                            <div className="code-center">
                                                <QRCode
                                                    size={150}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%", paddingBottom: "10px" }}
                                                    value={props?.privilege?.privilege_code}
                                                    viewBox={`0 0 150 150`}
                                                />
                                                <p>{props?.privilege?.privilege_code}</p>

                                            </div>
                                        </div>
                                        {/* <div className="group-code">
                                            <div className="code-center">
                                                <small style={{ display: 'block' }}>{props?.privilege?.privilege_code}</small>
                                            </div>
                                        </div> */}
                                    </Tab>
                                </Tabs>

                                <div style={{
                                    fontFamily: "DB Heavent",
                                    fontStyle: "normal",
                                    fontWeight: "bold",
                                    fontSize: "25px",
                                    lineHeight: "28px",
                                    color: "#000000",
                                }}>
                                    <p>
                                        กรุณาแสดงที่ร้านค้าเพื่อแลกรับสิทธิ์
                                        {!isExpire && totalSec > 0 && <CountDownTimer hoursMinSecs={totalSec} onExpire={() => handleExpire()} />}

                                    </p>
                                    <p style={{ color: "red" }}>
                                        {isExpire || totalSec < 0  && 'คูปองหมดอายุ'}
                                    </p>

                                </div>

                                <svg height="12px" width="100%"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1">
                                    <line className="dashed" x1="0"
                                        x2="100%" y1="20" y2="20" />
                                </svg>
                                <div className="btn-group">
                                    <button
                                        type="button" className="btn"
                                        style={{
                                            fontFamily: "DB Heavent",
                                            fontStyle: "normal",
                                            fontWeight: "normal",
                                            fontSize: "22px",
                                            color: "#949494"
                                        }}
                                        onClick={() => props.handleClose(false)}
                                    >ปิด</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>

        </>
    )
}
export default OpenRewardModal
