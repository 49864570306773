import React from 'react';

const CountDownTimer = ({ hoursMinSecs, onExpire }) => {
  // const { seconds = 3 } = hoursMinSecs;
  const [secs, setTime] = React.useState(hoursMinSecs);
  const format = (sec) => {
    let seconds = sec % 60;
    let minutes = Math.floor(sec / 60) % 60;
    let hous = Math.floor(sec / 60 / 60) % 60;
    var day = Math.floor(sec / (3600 * 24));
    minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;
    seconds = seconds.toString().length === 1 ? '0' + seconds : seconds;
    if (day + hous + minutes + seconds == 0) {
      onExpire();
    }
    return (
      <div className="countdown_box">
        <div className="countdown_box_item">
          <p>
            {'วัน'}
            <span>{day}</span>
          </p>
        </div>
        <div className="countdown_box_item">
          <p>
            {'ชั่วโมง'}
            <span>{hous}</span>
          </p>
        </div>
        <div className="countdown_box_item">
          <p>
            {'นาที'}
            <span>{minutes}</span>
          </p>
        </div>
        <div className="countdown_box_item">
          <p>
            {'วินาที'}
            <span>{seconds}</span>
          </p>
        </div>
      </div>
    );
  };


  React.useEffect(() => {
    const timerId = setInterval(() => {
      const newCount = secs - 1;
      setTime(newCount >= 0 ? newCount : 0);
      format(secs);
    }, 1000);
    return () => clearInterval(timerId);
  });

  return format(secs);
};

export default CountDownTimer;
