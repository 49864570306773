import React, { useState, useEffect } from 'react'
import HeaderBackPoint from "../../components/HeaderBackPoint"
import { Link, useHistory } from "react-router-dom";
import { apiUrl } from '../../constants';
import axios from "axios";

import "./Shipping.scss"
const Shipping = (props) => {
  // console.log(props.location.state);
  let token = localStorage.getItem("token")
  const cover_token = token ? JSON.parse(token) : ""
  let url = new URL(window.location.href)
  const id = url.searchParams.get("id");
  const [itemRedeem, setItemRedeem] = useState([]);
  const [itemTrackShipping, setItemTrackShipping] = useState([
    {dateTH: false, statusText: "เตรียมจัดส่งสินค้า", now: false},
    {dateTH: false, statusText: "ระหว่างดำเนินการจัดส่ง", now: false},
    {dateTH: false, statusText: "ส่งสินค้าเรียบร้อย", now: false},
  ]);

  useEffect(() => {
    setItemRedeem(props.location.state.data)
    getTrackShipping(cover_token);
  }, [])

  const getTrackShipping = async (token) => {
    const apiUrls = `${apiUrl}/deliveryStatus?redeemHistoryId=${props.location.state.data.id}`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      let event = []
      itemTrackShipping.map((data, index) => {
        let index1 = index
        let dataReal
        let date
        let dateTH = data.dateTH;
        let now = data.now;
        let statusText = data.statusText;
        res.data.map((data2, index) => {
          if (index1 == index) {
            dataReal = data2
            date = new Date(data2.date)
            dateTH = date.toLocaleString('th-TH', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })

            if (res.data.length == index + 1) {
              now = true
            }
          }
        })
        let dataDupicate = Object.assign({}, dataReal, { dateTH: dateTH, statusText: statusText, now: now});

        event.push(dataDupicate)
      })
      console.log(event);
      setItemTrackShipping(event)

    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <div className="shipping-page pb-3">
      <div className="title-header p-3">
        <HeaderBackPoint topic={'รายละเอียด'} path={`/other?token=${cover_token}&tab=history`} />
      </div>

      <div className="list-redeem row py-2 m-3">
        <div className="col-2">
          <img src={itemRedeem.deal_image}/>
        </div>
        <div className="w-50">
          <span className="list-name">{itemRedeem.deal_name}</span>
          <span className="font-size-s txt-status d-block">{itemRedeem.date_th} น.</span>
        </div>
        <div className="col-4">
          <span className="d-block font-size-s txt-point text-danger">-{itemRedeem.total_deal_point} คะแนน</span>
          <strong className="mt-2 d-block txt-point text-danger">{itemRedeem.qty} รางวัล</strong>
        </div>
      </div>

      <div className="timeline-wrapper p-3 m-3">
          <span className="timeline-title d-block pb-2">สถานะการจัดส่ง</span>
          <ul className="StepProgress mb-0">
              {itemTrackShipping.map((item, index) => {
                  return (
                      <li className={`StepProgress-item${item.now ? ' now' : ''}`} key={index}>
                          <span className="Progress-status d-block pb-1 ">{item.statusText}</span>
                          <span className="Progress-date-time d-block pb-1 ">{item.dateTH ? `เมื่อ ${item.dateTH } น.` : '-'} </span>
                          {/* <span className="Progress-address d-block ">{item.address}</span> */}
                      </li>
                  );
              })}
          </ul>
      </div>
      
    </div>
  )
}

export default Shipping
