import React from 'react'
import { Link, useHistory } from "react-router-dom";

const HeaderBackPoint = (props) => {
    const history = useHistory();

    return (
        <div className={`${Number(window.screen.width) > 768 ? "container" : ""}`}>
            <div className="row no-pad"
                style={{
                    alignItems: "center",
                    alignSelf: "center",
                }}>
                <div className="pl-3 col-6">
                    {props.path ?
                        <a onClick={() => {
                            // console.log(props.path)
                            // history.push(props.path)
                            history.goBack()
                        }}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                className="marginTop-icon"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8328 5.8333H3.97417L7.82577 1.99813C8.04555 1.77862 8.16902 1.48091 8.16902 1.17048C8.16902 0.860046 8.04555 0.562331 7.82577 0.342824C7.60599 0.123318 7.3079 0 6.99709 0C6.68628 0 6.38819 0.123318 6.16841 0.342824L0.332654 6.17135C0.226396 6.28222 0.143102 6.41294 0.0875521 6.55604C-0.0291841 6.83984 -0.0291841 7.15817 0.0875521 7.44197C0.143102 7.58507 0.226396 7.71579 0.332654 7.82666L6.16841 13.6552C6.27691 13.7644 6.406 13.8512 6.54823 13.9103C6.69046 13.9695 6.84301 14 6.99709 14C7.15117 14 7.30372 13.9695 7.44595 13.9103C7.58818 13.8512 7.71727 13.7644 7.82577 13.6552C7.93516 13.5468 8.02199 13.4179 8.08125 13.2758C8.1405 13.1338 8.17101 12.9814 8.17101 12.8275C8.17101 12.6736 8.1405 12.5213 8.08125 12.3792C8.02199 12.2372 7.93516 12.1083 7.82577 11.9999L3.97417 8.16471H12.8328C13.1424 8.16471 13.4393 8.0419 13.6581 7.82328C13.877 7.60467 14 7.30817 14 6.99901C14 6.68984 13.877 6.39334 13.6581 6.17473C13.4393 5.95611 13.1424 5.8333 12.8328 5.8333Z" fill="black" />
                            </svg>
                        </a>
                        :
                        null}
                    <span style={{
                        fontFamily: "DB Heavent",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "24px",
                        lineHeight: "29px",
                        color: "#000000",
                        paddingLeft: "10px",
                        paddingTop: "-10px",
                    }}>{props.topic}</span>
                </div>
                <div className="col d-flex align-items-end justify-content-center flex-column" style={{
                    textAlign: "right"
                }}>
                    {props.mypoint ?
                        <>
                            <div className="d-flex align-items-center justify-content-end">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                    className="marginTop-14-icon"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7 14.5C10.866 14.5 14 11.366 14 7.5C14 3.63401 10.866 0.5 7 0.5C3.13401 0.5 0 3.63401 0 7.5C0 11.366 3.13401 14.5 7 14.5ZM9.6226 7.55603C9.72838 7.30092 9.78127 7.02714 9.78127 6.73469C9.78127 6.45469 9.73149 6.19025 9.63194 5.94136C9.53238 5.69247 9.38927 5.4778 9.2026 5.29736C9.01594 5.11692 8.78571 4.9738 8.51194 4.86803C8.23816 4.76225 7.93016 4.70936 7.58794 4.70936H5.31994V11H6.53327V8.80669H7.53194C7.87416 8.80669 8.18527 8.7538 8.46527 8.64803C8.74527 8.54225 8.98171 8.39603 9.1746 8.20936C9.36749 8.02269 9.51683 7.80492 9.6226 7.55603ZM8.31594 6.02536C8.48394 6.19958 8.56794 6.43603 8.56794 6.73469C8.56794 7.05203 8.4746 7.30403 8.28794 7.49069C8.10749 7.67114 7.85549 7.76136 7.53194 7.76136H6.53327V5.75469H7.58794C7.90527 5.75469 8.14794 5.84492 8.31594 6.02536Z" fill="url(#paint0_linear)" />
                                    <defs>
                                        <linearGradient id="paint0_linear" x1="7" y1="0.5" x2="7" y2="14.5" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FFCF00" />
                                            <stop offset="1" stopColor="#FDAB00" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <span style={{
                                    fontFamily: "DB Heavent",
                                    fontStyle: "normal",
                                    fontWeight: "bold",
                                    fontSize: "27px",
                                    lineHeight: "32px",
                                    color: "#000000",
                                    paddingLeft: "10px",
                                    textAlign: "right"
                                }}>{props.mypoint}</span>
                            </div>
                            <span style={{
                                fontFamily: "DB Heavent",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "18px",
                                lineHeight: "19px",
                                color: "#929292",
                                textAlign: "right"
                            }}>คะแนนคงเหลือ</span>
                        </>
                        :
                        <>
                            <a style={{
                                fontFamily: "DB Heavent",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "18px",
                                lineHeight: "19px",
                                textAlign: "right",
                                fontWeight: '600',
                                color: "#00833b"
                            }}
                                onClick={() => {
                                    history.push(props.path2, { mode: 0 })
                                }}
                            >{props.add}</a>
                        </>

                    }
                </div>
            </div>
        </div >
    )
}

export default HeaderBackPoint
