import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import "./Address.scss";
import HeaderBackPoint from "../../components/HeaderBackPoint"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Modal from '@material-ui/core/Modal';
import { apiUrl } from '../../constants';
import axios from "axios";
import Swal from "sweetalert2";

const Address = (props) => {
  // console.log(props.location.state);
  const [open, setOpen] = useState(false);
  const [address, setaddress] = useState([]);
  const history = useHistory();
  const [coverToken, setCoverToken] = useState();
  const [coverId, setCoverId] = useState();
  const [value, setValue] = useState(-1);
  const [loadButton, setLoadButton] = useState(false);
  const [isUploadIdCard, setIsUploadIdCard] = useState(false);

  const handleChange = (event) => {
    setValue(Number(event.target.value));
  };


  useEffect(() => {
    const { price } = props.location.state
    var token = localStorage.getItem("token")
    var cover_token = token ? JSON.parse(token) : ""
    var id = localStorage.getItem("id")
    var cover_id = id ? JSON.parse(id) : ""

    setCoverToken(cover_token)
    setCoverId(cover_id)
    getAddressList(cover_token)
    if (price >= 1000) {
      setIsUploadIdCard(true)
    }
  }, [])

  const gotohome = () => {
    setOpen(false);
    history.push(`/privilege?token=${coverToken}`)
  }

  const handleConfirm = (cover_privilege_redeem_id) => {
    const apiUrls = `${apiUrl}/privilege/redeem/setAddress`;
    axios({
      method: "post",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${coverToken}`,
      },
      data: {
        privilegeRedeemIds: cover_privilege_redeem_id,
        clientAddressId: value
      }
    }).then((res) => {
      setOpen(true);
    }).catch(function (error) {
      if (error.response) {
        Swal.fire({
          title: "ผิดพลาด",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "ตกลง",
          confirmButtonColor: '#00833b',
        });
      }
    });
  }

  const getAddressList = async (token) => {
    await axios({
      method: "get",
      url: `${apiUrl}/client/address`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      let address = []
      res.data.map((data, key) => {
        data = {
          id: data.id,
          name: data.first_name ? data.first_name + ' ' + (data.last_name != "null" && data.last_name != null ? data.last_name : '') : "-",
          key: key + 1,
          detail: data.address1 ? data.address1 : data.location_name,
          district: data.district,
          district_id: data.district_id,
          phone: data.phone,
          post_code: data.post_code,
          province: data.province,
          province_id: data.province_id,
          subDistrict: data.subDistrict,
          sub_district_id: data.sub_district_id,
          is_address_complete: data.is_address_complete,
        }

        address.push(data)
      })
      setaddress(address)

      address.length > 0 ? setValue(address[0].id) : setValue(null)

    });
  };
  const next = async () => {
    setLoadButton(true)
    let addressSelect = await address.find(i => i.id == value)
    const cover_data_redeem = props.location.state
    if (addressSelect.is_address_complete) {
      history.push(`/Upload?token=${coverToken}&id=${coverId}`, { addressSelect, cover_data_redeem })
    } else {
      Swal.fire({
        title: "แลกรับสิทธิ์ผิดพลาด !",
        text: "กรุณาเลือที่อยู่ให้ถูกต้อง",
        icon: "error",
        confirmButtonText: "ตกลง",
        confirmButtonColor: '#00833b',
      });
    }
  }
  const redeem = async () => {
    setLoadButton(true)
    let addressSelect = await address.find(i => i.id == value)
    if (addressSelect.is_address_complete) {
      const cover_data_redeem = props.location.state
      const apiUrls = `${apiUrl}/privilege/redeem`;
      const resultAll = axios({
        method: "post",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${coverToken}`,
        },
        data: cover_data_redeem,
      }).then((res) => {
        setLoadButton(false)

        let privilege_redeem_id_data = []
        res.data.map((data, key) => {
          privilege_redeem_id_data.push(data.privilege_redeem_id)
        })

        handleConfirm(privilege_redeem_id_data)
      }).catch(function (error) {
        setLoadButton(false)
        if (error.response) {
          Swal.fire({
            title: "แลกรับสิทธิ์ผิดพลาด !",
            text: error.response.data.message,
            icon: "error",
            confirmButtonText: "ตกลง",
            confirmButtonColor: '#00833b',
          });
        }
      });
    } else {
      Swal.fire({
        title: "แลกรับสิทธิ์ผิดพลาด !",
        text: "กรุณาเลือที่อยู่ให้ถูกต้อง",
        icon: "error",
        confirmButtonText: "ตกลง",
        confirmButtonColor: '#00833b',
      });
    }
  }

  const deleteAddress = (id_item) => {
    const apiUrls = `${apiUrl}/client/address/${id_item}`;
    Swal.fire({
      title: "ลบที่อยู่",
      text: "คุณต้องการจะลบที่อยู่นี้ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: '#00833b',
      cancelButtonColor: '#808080',
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          url: apiUrls,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${coverToken}`,
          },
        }).then((res) => {
          Swal.fire({
            title: "ลบที่อยู่",
            text: "คุณลบที่อยู่เรียบร้อย",
            icon: "success",
            confirmButtonText: "ตกลง",
            confirmButtonColor: '#00833b',
          });
          getAddressList(coverToken)
        }).catch(function (error) {
          if (error.response) {
            Swal.fire({
              title: "ผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              confirmButtonText: "ตกลง",
              confirmButtonColor: '#00833b',
            });
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "ลบที่อยู่",
          text: "คุณยกเลิกการลบที่อยู่",
          icon: "error",
          confirmButtonText: "ตกลง",
          confirmButtonColor: '#00833b',
        });
      }
    });
  };

  return (
    <>
      <div className="address-page">
        <HeaderBackPoint
          topic={"เลือกที่อยู่จัดส่ง"}
          // mypoint={mypoint} 
          add={"+ เพิ่มที่อยู่"}
          path={`/Detail?token=${coverToken}&id=${coverId}`}
          path2={`/CreateAddress?token=${coverToken}&id=${coverId}`}
        />
      </div>
      <svg height="11px" width="100%"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1">

        <line className="dashed" x1="0"
          x2="100%" y1="20" y2="20" />
      </svg>
      <div className="address-page-body">
        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
          {
            address.length > 0 ?
              address.map((data, key) => {
                return <div key={key}><div className="container">
                  <div className="row no-pad mt-3">
                    <div className="col-8">
                      {!data.is_address_complete ?
                        <FormControlLabel value={data.id} control={<Radio />}
                          disabled={!data.is_address_complete}
                          checked={false}
                          label={
                            <div className="ml-2">
                              <div className="text-title font-weight-bold">
                                {data.name}
                              </div>
                              <div className="text-address">
                                {data.detail} {data.subDistrict?.name_th} {data.district.name_th} {data.province.name_th} {data.post_code}
                              </div>
                              <div className="text-tal">
                                {data.phone}
                              </div>
                              {!data.is_address_complete ?
                                <div className="text-tal text-danger">กรุณากรอกข้อมูลให้ครบถ้วน</div>
                                : ''}

                            </div>
                          } /> :
                        <FormControlLabel value={data.id} control={<Radio />}
                          label={
                            <div className="ml-2">
                              <div className="text-title font-weight-bold">
                                {data.name}
                              </div>
                              <div className="text-address">
                                {data.detail} {data.subDistrict?.name_th} {data.district.name_th} {data.province.name_th} {data.post_code}
                              </div>
                              <div className="text-tal">
                                {data.phone}
                              </div>
                              {!data.is_address_complete ?
                                <div className="text-tal text-danger">กรุณากรอกข้อมูลให้ครบถ้วน</div>
                                : ''}

                            </div>
                          } />
                      }

                    </div>
                    <div className="col-4 pt-1 d-flex justify-content-end">
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          history.push(`/CreateAddress?token=${coverToken}&id=${coverId}`, { mode: 1, data: data })
                        }}
                      >
                        <path d="M8.60386 2.7923H2.68975C2.2416 2.7923 1.8118 2.97032 1.49492 3.28721C1.17803 3.6041 1 4.0339 1 4.48204V16.3103C1 16.7584 1.17803 17.1882 1.49492 17.5051C1.8118 17.822 2.2416 18 2.68975 18H14.518C14.9661 18 15.3959 17.822 15.7128 17.5051C16.0297 17.1882 16.2077 16.7584 16.2077 16.3103V10.3962" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14.9404 1.52494C15.2765 1.18883 15.7324 1 16.2077 1C16.683 1 17.1389 1.18883 17.475 1.52494C17.8111 1.86105 17.9999 2.31691 17.9999 2.79225C17.9999 3.26758 17.8111 3.72345 17.475 4.05956L9.44871 12.0859L6.06921 12.9307L6.91409 9.55123L14.9404 1.52494Z" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>

                      <svg className="ml-2" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          deleteAddress(data.id)
                        }}
                      >
                        <path d="M2.75 5.5H4.58333H19.25" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.33337 5.49998V3.66665C7.33337 3.18042 7.52653 2.7141 7.87035 2.37028C8.21416 2.02647 8.68048 1.83331 9.16671 1.83331H12.8334C13.3196 1.83331 13.7859 2.02647 14.1297 2.37028C14.4736 2.7141 14.6667 3.18042 14.6667 3.66665V5.49998M17.4167 5.49998V18.3333C17.4167 18.8195 17.2236 19.2859 16.8797 19.6297C16.5359 19.9735 16.0696 20.1666 15.5834 20.1666H6.41671C5.93048 20.1666 5.46416 19.9735 5.12034 19.6297C4.77653 19.2859 4.58337 18.8195 4.58337 18.3333V5.49998H17.4167Z" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12.8334 10.0833V15.5833" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.16663 10.0833V15.5833" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>

                    </div>
                  </div>
                </div>
                  <svg height="12px" width="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1">

                    <line className="dashed" x1="0"
                      x2="100%" y1="20" y2="20" />
                  </svg>
                </div>
              })
              :
              <center className="center-page">
                <span className="text-topic-no-address">ไม่มีที่อยู่จัดส่ง</span>
                <br />
                <span className="text-detail-no-address">กรุณาสร้างที่อยู่จัดส่งของคุณ</span>
              </center>
          }


        </RadioGroup>


      </div>

      <div style={{
        marginBottom: `${Number(window.screen.height) > 1000 ? "200px" : "100px"}`
      }}></div>

      <div className="footer-page-address">
        <div className={`${Number(window.screen.width) > 600 ? "container" : ""}`}>
          <div className="row no-pad">
            <div className="col-12">
              <button className="a-set btn"
                disabled={loadButton}
                onClick={() => isUploadIdCard ? next() : redeem()}
              >
                {isUploadIdCard ? 'ถัดไป' : 'ยืนยัน'}
              </button>
            </div>
          </div>
        </div>
      </div>


      <Modal
        open={open}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-sm modal-dialog-centered w-75 m-auto">
          <div className="modal-content" style={{ borderRadius: '12px' }}>
            <div className="mt-3" style={{
              textAlign: "center"
            }}>
              <div className="container">

                <span style={{
                  fontFamily: "DB Heavent",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "34px",
                  lineHeight: "41px",
                  color: "#000000",
                }}>ยินดีด้วย!</span>
                <br />

                <img src={'/privilegeimage/congratulations.png'} style={{
                  height: "60%",
                  width: "50%",
                  maxHeight: "300px",
                  maxWidth: "300px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }} />

              </div>
            </div>

            <div className="center">
              <div className="redeem">
                <span className="text-redeem">
                  การแลกรับของรางวัลสำเร็จ
                </span>
              </div>
            </div>

            <div className="center mt-4 mb-3">
              <button className="btn button-text" onClick={() => gotohome()}>กลับสู่หน้าหลัก</button>
            </div>


          </div>
        </div>
      </Modal>

    </>
  )
}

export default Address
