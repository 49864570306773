import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga';
import { KEYTEST } from '../../constants/index';
const Error = () => {

  useEffect(() => {
    console.log(KEYTEST);
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + KEYTEST;
    script.async = true;
    document.head.appendChild(script);
    // Initialize Google Tag Manager
    var dataLayer = window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', KEYTEST);
  }, []);
  function handleClick() {
    // ส่งข้อมูล event ไปยัง Google Analytics
    console.log(KEYTEST);
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + KEYTEST;
    script.async = true;
    document.head.appendChild(script);
    var dataLayer = window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('event', 'gift_reward_views', {
      'gift_reward_name': 'KFC',
      'gift_reward_category': 'Food & Beverages'
    });
  }

  return (
    <div className="container" style={{ marginTop: "50px" }}>
      <div className="page-wrap d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div className="mb-4 lead">
                Session expired

                {/* <button onClick={() => handleClick()}>test</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
